import { JsonObject, JsonProperty } from 'json2typescript';
import { CallerSettings } from 'app/models/settings/caller-settings';
import { CallpointSettings } from 'app/models/settings/callpoint-settings';
import { ProjectSettings } from 'app/models/settings/project-settings';

@JsonObject
export class User {
   @JsonProperty('userId')
   userId: number = undefined;

   @JsonProperty('companyId')
   companyId: number = undefined;

   @JsonProperty('username')
   username: string = undefined;

   @JsonProperty('fullname')
   fullname: string = undefined;

   @JsonProperty('phoneNumber')
   phoneNumber: string = undefined;

   @JsonProperty('cognitoUserId')
   cognitoUserId: string = undefined;

   @JsonProperty('isAdmin')
   isAdmin: boolean = undefined;

   @JsonProperty('isCompanyAdmin')
   isCompanyAdmin: boolean = undefined;
   
   @JsonProperty('isMFAEnabled')
   isMFAEnabled: boolean = undefined;

   @JsonProperty('status')
   status: string = undefined;

   @JsonProperty('callerSettings', CallerSettings, true)
   public callerSettings: CallerSettings = undefined;

   @JsonProperty('callpointSettings', CallpointSettings, true)
   public callpointSettings: CallpointSettings = undefined;

   @JsonProperty('projectSettings', ProjectSettings, true)
   public projectSettings: ProjectSettings = undefined;
}
