import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BrowserWindowService } from 'app/services/browser-window.service';
import { ApplicationStore } from 'app/stores/application-store';
import { UserListComponent } from 'app/admin-workspace/user-list/user-list.component';
import { DeleteUserDialogComponent } from 'app/admin-workspace/delete-user-dialog/delete-user-dialog.component';
import { AddEditUserDialogComponent } from 'app/admin-workspace/add-edit-user-dialog/add-edit-user-dialog.component';
import { User } from 'app/models/user';
import { DialogMode } from 'app/models/diary/event-dialog-modes';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { environment } from 'environments/environment';

/**
 * Panel component for hosting the user list and buttons.
 */
@Component({
   selector: 'callsmart-admin-user-panel',
   templateUrl: './admin-user-panel.component.html'
})
export class AdminUserPanelComponent implements OnInit {

   public scrollHeight: string = "200px";
   public workspaceHeight: number;
   public superAdminsOnly:boolean = false;

   @ViewChild(UserListComponent)
   public userList: UserListComponent;

   ///--------------------delete dialog parameters -----------------
   // Determines whether to display the  dialog.
   public showDeleteUserDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public deleteUserDialog = DeleteUserDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public deleteUserDialogInput = {
      display: false,
      user: null
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public deleteUserDialogOutput = {
      delete: () => this.onDeleteUserDeleteEvent(),
      cancel: () => this.onDeleteUserCancelEvent()
   };

   ///--------------------add edit dialog parameters -----------------
   // Determines whether to display the  dialog.
   public showAddEditUserDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public addEditUserDialog = AddEditUserDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public addEditDialogInput = {
      display: false,
      user: null,
      headerText: null,
      dialogMode: DialogMode.add
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public addEditDialogOutput = {
      save: () => this.onAddEditSaveEvent(),
      cancel: () => this.onAddEditCancelEvent()
   };

   //---------------------------------------------------
   constructor(private _applicationStore: ApplicationStore, private windowService: BrowserWindowService, private router: Router) {
      //subscribe to the window resize event
      windowService.height$.subscribe((value: number) => {
         this.resizeWorkspace(value);
      });
   }

   public ngOnInit() {
      var loggedInUser = this._applicationStore.authenticationStore.loggedInUser.username;
      var superAdminUsers = environment.SuperAdminUsers.split(',');
      this.superAdminsOnly =  superAdminUsers.includes(loggedInUser);
   }

   public onDeleteUser() {
      this.showDeleteUserDialog = true;
      this.deleteUserDialogInput.display = true;
      this.deleteUserDialogInput.user = this.userList.selectedUsers;
   }

   // cancel button from the delete User  dialog
   public onDeleteUserCancelEvent() {
      this.showDeleteUserDialog = false;
      this.deleteUserDialogInput.display = false;
   }

   // delete button from the delete user  dialog
   public onDeleteUserDeleteEvent() {
      this.showDeleteUserDialog = false;
      this.deleteUserDialogInput.display = false;
   }

   // save button from the delete project  dialog
   public onAddEditSaveEvent() {
      this.showAddEditUserDialog = false;
      this.addEditDialogInput.display = false;
   }

   // cancel button from the addedit project  dialog
   public onAddEditCancelEvent() {
      this.showAddEditUserDialog = false;
      this.addEditDialogInput.display = false;
   }

   public onEditUser() {
      this.showAddEditUserDialog = true;
      this.addEditDialogInput.dialogMode = DialogMode.edit;
      this.addEditDialogInput.display = true;

      // Create a copy of the user in case the user makes changes and decides to cancel. 
      let user: User = CallsmartUtils.deepClone<User>(this.userList.selectedUsers, User);
      this.addEditDialogInput.user = user;
      this.addEditDialogInput.headerText = "Edit user";
   }

   public onCreateUser(){
      this.showAddEditUserDialog = true;
      this.addEditDialogInput.dialogMode = DialogMode.add;
      this.addEditDialogInput.display = true;
      this.addEditDialogInput.user = new User();
      this.addEditDialogInput.headerText = "Add user";
   }

   public onExport() {
      let data = this.userList.users.map(x => x.username).join('; ');
      //console.log(data);
      CallsmartUtils.downloadTxtFile(data, 'Export - emails.txt');
   }

   // Adjust the height of the data grid based on the browser height so that the content
   // always fit without showing vertical scrollbar.
   private resizeWorkspace(browserHeight: number) {
      this.workspaceHeight = browserHeight - 220;
      this.scrollHeight = this.workspaceHeight * 0.80 + 'px';
   }

   public syncAWSCognitoUserPool(){
      this._applicationStore.sysAdminStore.syncAWSCognitoUserPool();
   }
}
