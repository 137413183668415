import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment/moment';

import { Event } from 'app/models/diary/event';
import { EventsAndMetrics } from 'app/models/events-metrics';
import { environment } from 'environments/environment';
import { LockingTypes } from 'app/models/diary/locking-Types';
import { ScheduleWarnings } from '../models/schedule-warnings';

// this service is used purely for data acess CRUD operations to the data base
// the serbice is normally called from with in a store

const noCachingHttpHeaders = new HttpHeaders({
   'Cache-control': 'no-cache',
   'Expires': '0',
   'Pragma': 'no-cache'
});

@Injectable()
export class ScheduleService {

   scheduleUrl = (callerId: number) => `${environment.baseUrl}api/schedule/caller/${callerId}`;
   scheduleUrl2 = (callerId: number) => `${environment.baseUrl}api/schedule/callers/${callerId}`;

   public constructor(private _http: HttpClient) { }

   // this clears all vists from the schedul leaving behind only locked visits
   public clearScheduleUnlocked(callerId: number) {
      // this.setStateBeginOp(false);
      let endpoint: string = `${this.scheduleUrl(callerId)}?action=clearunlocked`;
      return this._http.delete(endpoint)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)))
   }

   // this clears all vists in the schedule
   public clearSchedule(callerId: number) {
      // this.setStateBeginOp(false);
      let endpoint: string = `${this.scheduleUrl(callerId)}?action=clearall`;
      return this._http.delete(endpoint)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)))
   }

   public getXmlSchedule(callerId: number) {
      let url: string = `${this.scheduleUrl(callerId)}/xml`;
      return this._http.get(url)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public getXmlScheduleRequest(callerId: number, dayIndex: number) {
      let url: string = `${this.scheduleUrl(callerId)}/${dayIndex}/requestxml`;
      return this._http.get(url)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public getJsonSchedule(callerId: number) {
      let url: string = `${this.scheduleUrl(callerId)}/json`;
      return this._http.get(url)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }
   
   public generateSchedule(callerId: number, scheduleType: string) {
      let url: string = `${this.scheduleUrl(callerId)}?scheduleType=${scheduleType}`;

      return this._http.post(url, null, { headers: noCachingHttpHeaders, responseType: 'text' })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public generateScheduleForDay(callerId: number, scheduleType: string, dayIndex: number) {
      let url: string = `${this.scheduleUrl(callerId)}/${dayIndex}`;

      return this._http.post(url, null, { headers: noCachingHttpHeaders, responseType: 'text' })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public generateScheduleWithUpdatedFrequency(callerId: number) {
      let url: string = `${this.scheduleUrl(callerId)}/updateFrequencyOptimisation`;

      return this._http.post(url, null, { headers: noCachingHttpHeaders, responseType: 'text' })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }


   public checkScheduleStatus(callerId: number): Observable<any> {
      let url: string = `${this.scheduleUrl(callerId)}/status`;
      return this._http.get(url)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)))
   }

   public getSchedule(projectId: number, callerId: number): Observable<any> {
      let url: string = `${this.scheduleUrl(callerId)}/json`;

      return this._http.get(url)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               if (response.schedule == null || response.schedule.personDays == null) {
                  return [];
               }
               return this.transformJsonSchedule(response);
            }),
            catchError(error => observableThrowError(error)));
   }

   public removeVisitFromSchedule(callerId: number, dayIndex: number, visitIndex: number): Observable<any> {
      let url: string = `${this.scheduleUrl2(callerId)}/remove-visit/${dayIndex}/${visitIndex}`;

      return this._http.put(url, null)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               return this.transformScheduleFromJson(response);
            }),
            catchError(error => observableThrowError(error)));
   }

   public removeLunchFromSchedule(callerId: number, dayIndex: number): Observable<any> {
      let url: string = `${this.scheduleUrl2(callerId)}/remove-lunch/${dayIndex}`;

      return this._http.put(url, null)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               return this.transformJsonSchedule(response);
            }),
            catchError(error => observableThrowError(error)));
   }

   public addLunchToSchedule(callerId: number, dayIndex: number): Observable<any> {

      let url: string = `${this.scheduleUrl2(callerId)}/add-lunch/dayIndex/${dayIndex}`;

      return this._http.put(url, null)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let schedule: string = JSON.parse(response.item1);
               let events: Event[] = this.transformJsonSchedule(schedule);
               let eventsAndWarnings: ScheduleWarnings = { events: events, warnings: response.item2 };
               return eventsAndWarnings;
            }),
            catchError(error => observableThrowError(error)));
   }

   public addOvernightToSchedule(callerId: number, dayIndex: number): Observable<any> {

      let url: string = `${this.scheduleUrl2(callerId)}/add-overnight/dayIndex/${dayIndex}`;

      return this._http.put(url, null)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let schedule: string = JSON.parse(response.item1);
               let events: Event[] = this.transformJsonSchedule(schedule);
               let eventsAndWarnings: ScheduleWarnings = { events: events, warnings: response.item2 };
               return eventsAndWarnings;
            }),
            catchError(error => observableThrowError(error)));
   }

   public removeOvernightFromSchedule(callerId: number, dayIndex: number): Observable<any> {

      let url: string = `${this.scheduleUrl2(callerId)}/remove-overnight/dayIndex/${dayIndex}`;

      return this._http.put(url, null)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let schedule: string = JSON.parse(response.item1);
               let events: Event[] = this.transformJsonSchedule(schedule);
               let eventsAndWarnings: ScheduleWarnings = { events: events, warnings: response.item2 };
               return eventsAndWarnings;
            }),
            catchError(error => observableThrowError(error)));
   }

   public lockVisit(callerId: number, dayIndex: number, visitIndex: number, lockingType: LockingTypes): Observable<any> {

      let url: string = `${environment.baseUrl}api/schedule/lockVisit/caller/${callerId}/dayIndex/${dayIndex}/visitIndex/${visitIndex}/lockType/`;

      switch (lockingType) {
         case LockingTypes.day:
            url = url + 'LockedToDay';
            break;
         case LockingTypes.dayAndTime:
            url = url + 'Locked';
            break;
         case LockingTypes.none:
            url = url + 'None';
            break;
      }

      return this._http.put(url, null)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               return this.transformJsonSchedule(response);
            }),
            catchError(error => observableThrowError(error)));
   }

   public lockCallpointVisits(callerId: number, callpointReferences: string[], lockingType: LockingTypes): Observable<any> {

      let url: string = `${this.scheduleUrl2(callerId)}/callpoints/visits/lockType/`

      switch (lockingType) {
         case LockingTypes.day:
            url = url + 'LockedToDay';
            break;
         case LockingTypes.dayAndTime:
            url = url + 'Locked';
            break;
         case LockingTypes.none:
            url = url + 'None';
            break;
      }

      return this._http.put(url, callpointReferences)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               return this.transformJsonSchedule(response);
            }),
            catchError(error => observableThrowError(error)));
   }

   public addVisitToSchedule(callerId: number, callpointId: string, dayIndex: number): Observable<any> {

      let url: string = `${this.scheduleUrl2(callerId)}/callpoints/${callpointId}/visits/dayIndex/${dayIndex}`;

      return this._http.put(url, null)
         .pipe(
            map((response: any) => {
               return this.transformScheduleFromJson(response);
            }),
            catchError(error => observableThrowError(error)));
   }

   public moveVisitInSchedule(callerId: number, callpointId: number, fromDayIndex: number,
      fromVisitIndex: number, toDayIndex: number, toDate: Date): Observable<any> {

      let toTimeTransformed: string;
      if (moment(toDate, moment.ISO_8601).isValid()) {
         let intermediateDate: moment.Moment = moment(toDate, moment.ISO_8601);
         toTimeTransformed = intermediateDate.format("HH:mm:ss");  // Gets rid of the timeoffset ISO_8601 part

         let url: string =
            `${this.scheduleUrl2(callerId)}/callpoints/${callpointId}/visits/fromDayIndex/${fromDayIndex}/fromVisitIndex/${fromVisitIndex}/toDayIndex/${toDayIndex}`;

         return this._http.put(url, { 'toTime': toTimeTransformed })
            .pipe(
               map((response: any) => {
                  let schedule: string = JSON.parse(response.item1);
                  let events: Event[] = this.transformJsonSchedule(schedule);
                  let eventsAndWarnings: ScheduleWarnings = { events: events, warnings: response.item2 };
                  return eventsAndWarnings;
               }),
               catchError(error => observableThrowError(error)));
      }
   }

   public getDrivetimeVersion() {
      let headers = new Headers();
      headers.append('Content-Type', 'application/json;');

      return this._http.get(`${environment.baseUrl}api/schedule/drivetime/version`, { responseType: 'text' })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public getOptimiserVersion() {
      let headers = new Headers();
      headers.append('Content-Type', 'application/json;');

      return this._http.get(`${environment.baseUrl}api/schedule/optimiser/version`, { responseType: 'text' })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public validateSwapDaysInSchedule(callerId: number, fromDayIndex: number, toDayIndex: number): Observable<any> {

      let url: string = `${this.scheduleUrl2(callerId)}/swap/fromDayIndex/${fromDayIndex}/toDayIndex/${toDayIndex}/validate`;

      return this._http.put(url, null)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let schedule: string = JSON.parse(response.item1);
               let events: Event[] = this.transformJsonSchedule(schedule);
               let eventsAndWarnings: ScheduleWarnings = { events: events, warnings: response.item2 };
               return eventsAndWarnings;
            }),
            catchError(error => observableThrowError(error)));
   }

   public swapDaysInSchedule(callerId: number, fromDayIndex: number, toDayIndex: number): Observable<any> {

      let url: string = `${this.scheduleUrl2(callerId)}/swap/fromDayIndex/${fromDayIndex}/toDayIndex/${toDayIndex}`;

      return this._http.put(url, null)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let schedule: string = JSON.parse(response.item1);
               let events: Event[] = this.transformJsonSchedule(schedule);
               let eventsAndWarnings: ScheduleWarnings = { events: events, warnings: response.item2 };
               return eventsAndWarnings;
            }),
            catchError(error => observableThrowError(error)));
   }

   private transformScheduleFromJson(schedule: any): EventsAndMetrics {
      let eventswithMetrics: EventsAndMetrics = {
         events : this.transformJsonSchedule(schedule),
         metrics : schedule.schedule.metrics
      };
      
      return eventswithMetrics
   }

   private transformJsonSchedule(schedule: any): Event[] {
      let events: Event[] = [];

      // Enumerate the days in the Schedule.
      schedule.schedule.personDays.personDay.forEach(day => {

         // Enumerate the appointments in a day.
         if (day.appointments == null) {
            // No appointments.
         }
         else if (Array.isArray(day.appointments.appointment)) {
            day.appointments.appointment.forEach(appointment => {
               let event: Event = Event.create(day.date, appointment);
               events.push(event);
               if (events.length === 1 && events[0].overnight) {
                  let overnightEvent = Event.createOvernight(new Date(day.date), events[0].start);
                  events.push(overnightEvent);
               }
               else if (events.length >= 2 && events[events.length - 2].overnight && events[events.length - 1].overnight) {
                  let overnightEvent = Event.createOvernight(events[events.length - 2].end, events[events.length - 1].start);
                  events.push(overnightEvent);
               }
            });
         }
         // Except if there is only one appointment on that day, then it won't be an array.
         else if (day.appointments.appointment) {
            let event: Event = Event.create(day.date, day.appointments.appointment);
            events.push(event);
         }
      });

      // There is a possibility that co-located callpoints will have a zero drive time home and
      // a overnight specified. In this case the diary should only display the overnights and not
      // the commute at the end of the daty. So delete any events where the duration is zero except
      // overnights. For co-located callpoints the overnight duration is also zero.
      events.forEach(event => {
         if (event.start.getTime() === event.end.getTime() && event.eventType !== 4) {
            let index = events.indexOf(event);
            if (index > -1) {
               events.splice(index, 1);
            }
         }
      });

      return events;
   }
}
