import { of, throwError } from 'rxjs';
import { concatMap, delay } from 'rxjs/operators';
import { JsonConvert } from "json2typescript";
import * as moment from "moment/moment";

import { CallerSettings } from "app/models/settings/caller-settings";
import { CallerSettingsViewModel } from "app/models/view-models/caller-settings-view";

declare function require(name: string): any; // requires is a way to reference javascript files
var FileSaver = require("file-saver");

export const retryCount = 3;

export class CallsmartUtils {
   // Email regular expression pattern to test against. Taken from Angular's EmailValidator.
   private static _EMAIL_PATTERN: RegExp = RegExp(
      "^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$"
   );

   private static _PHONE_PATTERN: RegExp = RegExp("^[+][0-9]{12,13}");
   // For use after deep clone an object in JavaScript. 
   // From https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
   // "Beware using the JSON.parse(JSON.stringify(obj)) method on Date objects - JSON.stringify(new Date()) returns a
   // string representation of the date in ISO format, which JSON.parse() doesn't convert back to a Date object."
   private static readonly dateColumnForTransformation: string[] = [
      // Project settings - although not date columns, add these keys so that each setting's child property keys are checked too.
      'callerSettings',
      'callpointSettings',
      'projectSettings',

      // Project properties
      'scheduleStartDate',
      'modifiedDate',
      'inUseHeartBeat',
      
      // callpointSettings properties
      'startDate',
      'endDate',
      'availability',

      // callerSettings properties
      'contractedWorkingHoursWeek',
      'contractedWorkingHoursMonday',
      'contractedWorkingHoursTuesday',
      'contractedWorkingHoursWednesday',
      'contractedWorkingHoursThursday',
      'contractedWorkingHoursFriday',
      'contractedWorkingHoursSaturday',
      'contractedWorkingHoursSunday',
      'lunchPeriod',

      // Event properties
      'start',
      'end',

      // Company properties
      'licenseExpiryDate',

      // Copy roll properties
      'RollForwardDateTime',

      // Report properties
      'startDate',
      'endDate',

      // Visit properties
      'date',
   ];

   // KM to Miles and back conversion factors.
   private static readonly _MILES_TO_KM_FACTOR = 0.621371192;
   private static readonly _KM_TO_MILES_FACTOR = 1.609344000614692;
   private static readonly dateFormat = "YYYY-MM-DDTHH:mm:ss";

   // Default value in milliseconds for polling interval when generating schedules.
   public static readonly STATUS_POLL_DEFAULT_INTERVAL = 1000;

   public static validateEmail(email: string): boolean {
      if (!CallsmartUtils._EMAIL_PATTERN.test(email)) {
         return false;
      }
      return true;
   }

   public static validatePhoneNumber(phoneNumber: string): boolean {
      if (!CallsmartUtils._PHONE_PATTERN.test(phoneNumber)) {
         return false;
      }
      return true;
   }
   // Deep clone a copy of the object of type T. classReference will be the type of object T.
   //
   // Usage example:
   // let callpointClone: Callpoint = CallsmartUtils.deepClone<Callpoint>(callpoint, Callpoint);
   //
   public static deepClone<T>(data: T, classReference: any): T {
      if (data !== null) {
         let jsonObject = JSON.parse(JSON.stringify(data));
         let jsonConvert: JsonConvert = new JsonConvert();
         jsonConvert.ignorePrimitiveChecks = true;
         let dataObject: T = jsonConvert.deserialize(
            jsonObject,
            classReference
         );
         return dataObject;
      } else return null;
   }

   // Gets the working time depending on the caller settings.
   // Always applies the ContractedWorkingHoursWeek. It doesn't take into account the contracted working hours for indivudual days.
   public static getWorkingTimeFromCallerSettingsViewModel(
      isStartTime: boolean,
      callerSettingsVm: CallerSettingsViewModel
   ): string {
      return CallsmartUtils.getWorkingTime(
         isStartTime,
         callerSettingsVm.contractedWorkingHoursWeek
      );
   }

   // Gets the working time depending on the caller settings.
   // Always applies the ContractedWorkingHoursWeek. It doesn't take into account the contracted working hours for indidual days.
   public static getWorkingTimeFromCallerSettingsModel(
      isStartTime: boolean,
      callerSettings: CallerSettings
   ): string {
      return CallsmartUtils.getWorkingTime(
         isStartTime,
         callerSettings.contractedWorkingHoursWeek
      );
   }

   // Gets the working time depending on the caller settings
   public static getWorkingTime(
      isStartTime: boolean,
      contractedWorkingHours: Date[]
   ): string {
      let workingTime: string = null;
      let hours: number;
      let minutes: number;

      // Checks whether working hours are the same for all working days
      if (contractedWorkingHours) {
         let weeklyWorkingTime: Date = isStartTime
            ? new Date(contractedWorkingHours[0])
            : new Date(contractedWorkingHours[1]);
         hours = weeklyWorkingTime.getHours();
         minutes = weeklyWorkingTime.getMinutes();
      } else {
         // Return some defaults.
         hours = isStartTime ? 8 : 18;
         minutes = isStartTime ? 0 : 30;
      }
      workingTime =
         (hours < 10 ? "0" + hours : hours) +
         ":" +
         (minutes < 10 ? "0" + minutes : minutes);
      return workingTime;
   }

   // Test to see if two dates are the same, returns true if they are, false otherwise.
   public static isEqualDates(firstDate: Date, secondDate: Date): boolean {
      let firstMomentDate: moment.Moment = moment(firstDate, moment.ISO_8601);
      let secondMomentDate: moment.Moment = moment(secondDate, moment.ISO_8601);

      // console.log(   "Comparing dates: firstMomentDate:" +   firstMomentDate + ", secondMomentDate:" +   secondMomentDate  );

      //if(firstMomentDate.isValid() && secondMomentDate.isValid())
      return firstMomentDate.isSame(secondMomentDate);
   }

   public static getEmptyDayCombinationGroups(
      numberOfWorkingDays: number,
      dayCombinations: string[]
   ): number[] {
      let emptySelectionDayCombinationGroups: number[] = [];
      if (numberOfWorkingDays > 1) {
         // Checks if there is any day combination group with no selection
         for (
            let workingDay: number = numberOfWorkingDays;
            workingDay > 1;
            workingDay--
         ) {
            let emptySelection: boolean = true;
            dayCombinations.forEach(item => {
               if (item.split(",").length === workingDay) {
                  emptySelection = false;
                  return;
               }
            });
            // Adding the dayCombination group to the empty selection array
            if (emptySelection) {
               emptySelectionDayCombinationGroups.push(workingDay);
            }
         }
      }
      return emptySelectionDayCombinationGroups;
   }

   public static isNullOrWhitespace(input) {
      if (typeof input === "undefined" || input == null) return true;
      return input.replace(/\s/g, "").length < 1;
   }

   public static isNullOrEmpty(input) {
      if (typeof input === "undefined" || input == null || input == "") {
         return true;
      }
      return input.length < 1;
   }

   public static isNumber(value: string | number): boolean {
      if (this.isNullOrEmpty(value)) {
         return false;
      } else {
         return !isNaN(Number(value.toString()));
      }
   }

   public static distanceConvert(value: number, unitIsMiles: boolean): number {
      if (unitIsMiles) {
         let mileValue: number = value * this._KM_TO_MILES_FACTOR;
         return mileValue;
      } else {
         let kmValue: number = value * this._MILES_TO_KM_FACTOR;
         return kmValue;
      }
   }

   public static formatDate(date: Date, format: string): string {
      if (!date) {
         return "";
      }
      var momentDate = moment(date);

      // If moment didn't understand the value, return it unformatted.
      if (!momentDate.isValid()) return date.toString();

      // Otherwise, return the date formatted as requested.
      return momentDate.format(format);
   }

   public static isDateValid(date: Date, format: string): boolean {
      var momentDate = moment(date, format);
      return momentDate.isValid();
   }

   // The datesClosed array is a flatted 2D array where all the weeks are next to each other
   // in a consecutive fashion. We need to determine if all the days are the same for each week or not
   // so that the logic can be used to drive the state of the contracted working days buttons
   // in the Caller settings.
   // Rule 1 if any day is Half Day ie 1 or 2 value then autmatically make it amber
   // Rule 2 If all the Mondays are different, for example week 1 Monday is selected
   // but week 2 Monday is not selected then we need to show the Monday button in amber colour to tell
   // the user that there are different selections for Monday for each week. This Monday comparison
   // will need to be done between all the weeks in the project cycle.
   public static getAmberStateForWorkingDays(datesClosed: string[], projectCycleLength: number): boolean[] {
      let amberDays: boolean[] = [false, false, false, false, false, false, false];

      amberDays[0] = this.isDayAmber(0, projectCycleLength, datesClosed);
      amberDays[1] = this.isDayAmber(1, projectCycleLength, datesClosed);
      amberDays[2] = this.isDayAmber(2, projectCycleLength, datesClosed);
      amberDays[3] = this.isDayAmber(3, projectCycleLength, datesClosed);
      amberDays[4] = this.isDayAmber(4, projectCycleLength, datesClosed);
      amberDays[5] = this.isDayAmber(5, projectCycleLength, datesClosed);
      amberDays[6] = this.isDayAmber(6, projectCycleLength, datesClosed);

      return amberDays;
   }

   // checks if any day of the week is part day ie 1 or 2 then amber
   // checks if the day in the week corresponds with the day in other call cyle weeks
   public static isDayAmber(dayIndex: number, projectCycleLength: number, datesClosed: string[]) {
      let dayMatch: boolean;

      if (projectCycleLength == 1) {
         // is this a part day
         if (datesClosed[dayIndex] === "1" || datesClosed[dayIndex] === "2") {
            dayMatch = false;
         }
         else {
            dayMatch = true;
         }
      }
      else {
         for (let weekIndex = 0; weekIndex < projectCycleLength - 1; weekIndex++) {
            // is this a part day
            if (datesClosed[dayIndex] === "1" || datesClosed[dayIndex] === "2") {
               dayMatch = false;
               break;
            }

            // Compare state for example day 0 of monday this week to monday of the next week...
            dayMatch = datesClosed[dayIndex] === datesClosed[dayIndex + 7];

            // If they match then update the index to get to the monday of next consecutive week...
            if (dayMatch) {
               dayIndex = dayIndex + 7;
            } else {
               // If they don't match then we already have a difference and there is no need to compare
               // the rest of the weeks, break and exit the week loop. This is an amber day
               break;
            }
         }
      }

      // Invert the state of the match to true, so that we know which buttons will be amber.
      return !dayMatch;
   }

   public static formatClosedDates(numOfWeeks: number, workingDays: boolean[]) {
      let formattedClosedDates: string[] = [];

      for (let weekIndex = 0; weekIndex < numOfWeeks; weekIndex++) {
         let week = workingDays.map(day => (day ? "o" : "x"));
         formattedClosedDates = formattedClosedDates.concat(week);
      }

      return formattedClosedDates;
   }

   // The best way of implementing retries for all the requests made to the server is
   // using Interceptors and the HttpClient class. They both have been introduced in Angular Version 4.3.
   // However, since the beginning of this project, we've been using Http class (Version 4.2) which means
   // we are not able to add interceptors to our project...
   // Because of that, we have to create this method here to be used every time a request is made
   // to the server in order to add the retries (3 retries in our case) when the app detects that
   // the connection is lost or rejected.
   public static retryRequest(error) {
      return error.pipe(
         concatMap((error: any, count) => {
            // If the error is an Internal server error or validation error then no more retries.
            if (count === retryCount || error.status == 500 || error.status == 400) {
               return throwError(error);
            }
            return of(error);
         }),
         delay(1000)
      );
   }

   public static downloadCsvFile(data: any, fileName: string) {
      // https://stackoverflow.com/questions/17879198/adding-utf-8-bom-to-string-blob
      // Write the file using the utf-8 character set, however MS Excel will not display the
      // extended characters correctly unless the byte order mark (BOM) is set, this has
      // to be prepended to the data as \uFEFF. The content is then encoded as UTF-8 BOM.
      let blob = new Blob(["\uFEFF" + data], {
         type: "text/csv;charset=utf-8"
      });
      FileSaver.saveAs(blob, fileName);
   }

   public static downloadTxtFile(data: any, fileName: string) {
      let blob = new Blob([data], { type: "text/txt" });
      FileSaver.saveAs(blob, fileName);
   }

   public static getIndexDayInCycle(selectedDayDate: Date, cycleStartDate: Date): number {
      let iniCycleDate: moment.Moment = moment(cycleStartDate).startOf('day');
      let selectedVisitDay: moment.Moment = moment(selectedDayDate).startOf('day');
      let dayIndex = selectedVisitDay.diff(iniCycleDate, 'days');
      return dayIndex;
   }

   public static round(value, precision) {
      let multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
   }

   public static transformObjectDates<T>(objectToTransform: T) {
      for (const key of Object.keys(objectToTransform)) {
         // Only format date fields.
         if (this.dateColumnForTransformation.includes(key)) {
            if (moment(objectToTransform[key], moment.ISO_8601).isValid()) {
               let intermediateDate: moment.Moment = moment(objectToTransform[key], moment.ISO_8601);
               objectToTransform[key] = intermediateDate.format(CallsmartUtils.dateFormat);  // Gets rid of the timeoffset ISO_8601 part
            }
            else if (objectToTransform[key] instanceof Array) {
               let index: number = 0;
               objectToTransform[key].forEach(item => {
                  if (moment(item, moment.ISO_8601).isValid()) {
                     let intermediateDate: moment.Moment = moment(item, moment.ISO_8601);
                     objectToTransform[key][index] = intermediateDate.format(CallsmartUtils.dateFormat); // Gets rid of the timeoffset ISO_8601
                  }
                  index++;
               });
            }
            else if (objectToTransform[key] instanceof Object) {
               this.transformObjectDates(objectToTransform[key])
            }
         }
      }
   }
}
