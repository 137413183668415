export const environment = {
  production: false,
  // locahost would be more generic and lead to fewer deploy errors, this would require the schedule service to have CORS turned on
  baseUrl: 'https://callsmart.caci.co.uk/callsmartService/',
  loadFirstProject: false,
  clientBaseUrl:'https://callsmart.caci.co.uk/',
  appVersion: require('../../package.json').version,
  cognitoUserRegion: "eu-west-2",
  cognitoUserPoolId: "eu-west-2_2sjgs0AWf",
  cognitoAppClientId: "1nii45qu9p97dm3kkdgmcqhljh",
  CognitoUserPoolEndpoint: "https://cognito-idp.eu-west-2.amazonaws.com/eu-west-2_2sjgs0AWf",

    // access to sync local users with cognito user pool
  SuperAdminUsers:"ryelugala@caci.co.uk"
};
