﻿import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// guards
import { AuthGuardService } from './services/auth-guard.service';
import { AdminGuardService } from 'app/services/admin-guard.service';
import { AllAdminGuardService } from 'app/services/all-admin-guard';
import { CompanyAdminGuardService } from 'app/services/company-admin-guard.service';
import { NewPasswordGuardService } from './services/new-password-guard.service';
import { SmsMfaVerificationGuardService } from './services/sms-mfa-verification-guard.service';

// components
import { DashboardWorkspaceComponent } from './dashboard-workspace/dashboard-workspace.component';
import { CallersWorkspaceComponent } from './callers-workspace/callers-workspace.component';
import { CallpointWorkspaceComponent } from './callpoint-workspace/callpoint-workspace.component';
import { DiaryWorkspaceComponent } from './diary-workspace/diary-workspace.component';
import { ScheduleWorkspaceComponent } from './schedule-workspace/schedule-workspace.component';
import { ScheduleTestWorkspaceComponent } from './schedule-test-workspace/schedule-test-workspace.component';
import { LoginComponent } from './login/login.component';
import { SysAdminWorkspaceComponent } from './sys-admin-workspace/sys-admin-workspace.component';
import { VisitsWorkspaceComponent } from './visits-workspace/visits-workspace.component';
import { StyleGuideComponent } from "app/styleguide-workspace/style-guide.component";
import { WizardTestComponent } from 'app/wizard-workspace/wizard-test/wizard-test.component';
import { NewProjectWorkspaceComponent } from 'app/new-project-workspace/new-project-workspace.component';
import { NotificationWorkspaceComponent } from 'app/notification-workspace/notification-workspace.component';
import { SettingsWorkspaceComponent } from 'app/settings-workspace/settings-workspace.component';
import { ProjectSettingsWorkspaceComponent } from 'app/project-settings-workspace/project-settings-workspace.component';
import { OpenProjectWorkspaceComponent } from 'app/open-project-workspace/open-project-workspace.component';
import { ProjectPropertiesEventComponent } from 'app/project-settings-workspace/project-properties-event/project-properties-event.component';
import { ProjectPropertiesCallpointsComponent } from 'app/project-settings-workspace/project-properties-callpoints/project-properties-callpoints.component';
import { UserCallpointSettingsComponent } from 'app/settings-workspace/user-callpoint-settings.component';
import { UserProjectSettingsComponent } from 'app/settings-workspace/user-project-settings.component';
import { UserCallerSettingsComponent } from 'app/settings-workspace/user-caller-settings.component';
import { ProjectPropertiesCallersComponent } from 'app/project-settings-workspace/project-properties-callers/project-properties-callers.component';
import { ProjectPropertiesSettingsComponent } from 'app/project-settings-workspace/project-properties-settings/project-properties-settings.component';
import { PendingChangesGuard } from 'app/services/pending-changes-guard.service';
import { LogoutComponent } from 'app/shared/logout/logout.component';
import { AdminWorkspaceComponent } from 'app/admin-workspace/admin-workspace.component';

import { UserListComponent } from 'app/admin-workspace/user-list/user-list.component';
import { CompanyListComponent } from 'app/admin-workspace/company-list/company-list.component';
import { AdminCompanyPanelComponent } from 'app/admin-workspace/admin-company-panel/admin-company-panel.component';
import { AdminUserPanelComponent } from 'app/admin-workspace/admin-user-panel/admin-user-panel.component';
import { AdminTravelModelPanelComponent } from 'app/admin-workspace/admin-travel-model-panel/admin-travel-model-panel.component';
import { AdminAuditPanelComponent } from 'app/admin-workspace/admin-audit-panel/admin-audit-panel.component';
import { ProjectStatusWorkspaceComponent } from 'app/project-status-workspace/project-status-workspace.component';
import { ErrorsComponent } from './shared/errors-component/errors.component';
import { ImportDataWorkspaceComponent } from './import-data-workspace/import-data-workspace.component';
import { CloseProjectComponent } from 'app/shared/close-project/close-project.component';
import { LegalComponent } from './login/legal/legal.component';
import { AdminProjectPanelComponent } from 'app/admin-workspace/admin-project-panel/admin-project-panel.component';
import { ExpiredPasswordComponent } from './expired-password/expired-password.component';
import { AdminMaintenancePanelComponent } from './admin-workspace/admin-maintenance-panel/admin-maintenance-panel.component';

import { NewPasswordComponent } from './login/new-password/new-password.component';
import { SmsMfaVerificationComponent } from './login/sms-mfa-verification/sms-mfa-verification.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';


const appRoutes: Routes = [
   { path: '', component: LoginComponent },
   { path: 'dashboard', component: DashboardWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'project-status', component: ProjectStatusWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'updating-callpoints', component: ProjectStatusWorkspaceComponent, canActivate: [AuthGuardService] },

   ///////////////////
   { path: 'updating-callpoints-callers', component: ProjectStatusWorkspaceComponent, canActivate: [AuthGuardService] },   
   ////////////////////

   { path: 'updating-callers', component: ProjectStatusWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'merging-callpoints', component: ProjectStatusWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'merging-callers', component: ProjectStatusWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'merging-callers-callpoints', component: ProjectStatusWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'callers', component: CallersWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'callpoints', component: CallpointWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'diary', component: DiaryWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'schedule', component: ScheduleWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'schedule-test', component: ScheduleTestWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'wizard-test', component: WizardTestComponent, canActivate: [AuthGuardService] },
   { path: 'sysadmin', component: SysAdminWorkspaceComponent },  // this page needs some securing as you can drop the database, needs an admin and auth guard
   { path: 'styleguide', component: StyleGuideComponent },
   { path: 'visits', component: VisitsWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'new-project', component: NewProjectWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'notification-center', component: NotificationWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'import-data', component: ImportDataWorkspaceComponent, canActivate: [AuthGuardService] },
   { path: 'logout', component: LogoutComponent, canActivate: [AuthGuardService] },
   { path: 'close-project', component: CloseProjectComponent, canActivate: [AuthGuardService] },
   { path: 'error', component: ErrorsComponent },
   { path: 'project-settings',
      component: ProjectSettingsWorkspaceComponent,
      children: [
         {
            path: '',
            redirectTo: 'settings',
            pathMatch: 'full', canActivate: [AuthGuardService]
         },
         {
            path: 'events',
            component: ProjectPropertiesEventComponent, canActivate: [AuthGuardService], canDeactivate: [PendingChangesGuard]
         },
         {
            path: 'callpoints',
            component: ProjectPropertiesCallpointsComponent, canActivate: [AuthGuardService], canDeactivate: [PendingChangesGuard]
         }
         ,
         {
            path: 'callers',
            component: ProjectPropertiesCallersComponent, canActivate: [AuthGuardService], canDeactivate: [PendingChangesGuard]
         }
         ,
         {
            path: 'settings',
            component: ProjectPropertiesSettingsComponent, canActivate: [AuthGuardService], canDeactivate: [PendingChangesGuard]
         }
      ] },
   { path: 'open-project', component: OpenProjectWorkspaceComponent, canActivate: [AuthGuardService] },

   {
      path: 'settings',
      component: SettingsWorkspaceComponent,
      children: [
         {
            path: '',
            redirectTo: 'project',
            pathMatch: 'full', canActivate: [AuthGuardService]
         },
         {
            path: 'callers',
            component: UserCallerSettingsComponent, canActivate: [AuthGuardService], canDeactivate: [PendingChangesGuard]
         },
         {
            path: 'project',
            component: UserProjectSettingsComponent, canActivate: [AuthGuardService], canDeactivate: [PendingChangesGuard]
         },
         {
            path: 'callpoints',
            component: UserCallpointSettingsComponent, canActivate: [AuthGuardService], canDeactivate: [PendingChangesGuard]
         }
      ]
   },

   {
      path: 'admin',
      component: AdminWorkspaceComponent,
      children: [
         {
            path: '',
            redirectTo: 'users',
            pathMatch: 'full', canActivate: [AllAdminGuardService]
         },
         {
            path: 'users',
            component: AdminUserPanelComponent, canActivate: [AllAdminGuardService]
         },
         {
            path: 'projects',
            component: AdminProjectPanelComponent, canActivate: [AuthGuardService]
         },
         {
            path: 'companies',
            component: AdminCompanyPanelComponent, canActivate: [AuthGuardService]
         },
         {
            path: 'travelmodels',
            component: AdminTravelModelPanelComponent, canActivate: [AuthGuardService]
         },
         {
            path: 'audit',
            component: AdminAuditPanelComponent, canActivate: [AuthGuardService]
         },
         {
            path: 'maintenance',
            component: AdminMaintenancePanelComponent, canActivate: [AuthGuardService]
         }
      ]
   },
   { path: 'legal', component: LegalComponent},
   { path: 'expired-password', component: ExpiredPasswordComponent},
   { path: 'new-password', component: NewPasswordComponent, canActivate: [NewPasswordGuardService]},
   { path: 'sms-mfa-verification', component: SmsMfaVerificationComponent, canActivate: [SmsMfaVerificationGuardService]},
   { path: 'password-reset', component: PasswordResetComponent},
   { path: 'forgot-password', component: ForgotPasswordComponent},
   { path: '**', redirectTo: '/' },   
];

@NgModule({
   // imports: [RouterModule.forRoot(appRoutes)], // no hash location stratgey
   imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
   exports: [RouterModule],
   providers: [
      PendingChangesGuard
   ]
})
export class AppRoutingModule {

}
