import { Component, Input, EventEmitter, Output, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { Subscription } from 'rxjs';

/**
 * Dialog component to to allow the user to change there password
 * info pulled off the caci password policy
 * Passwords shall not be identical to User Name, nor shall they have the User or full name included within the password
 * Passwords shall be unique. Common passwords shall be avoided
 * CACI shall not be embedded in passwords
 * Contain both upper and lower case characters (e.g., a-z, A-Z)
 * Have digits and punctuation characters as well as letters
 * Are at least eight alphanumeric characters long
 * Are not words in any language, slang, dialect or jargon
 * Are not based on personal information, names of family, etc.
 * Are passphrases rather than single words. E.G. Thefrontdoorofmyhouseisred
 */
@Component({
   selector: 'callsmart-change-password',
   templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit, OnDestroy  {

   public username: string;
   public errorMsg: string = "";
   public showError: boolean = false;
   public passwordStrength: any;
   public passwordStrengthText:string = "Password Strength";
   private _passwordChanged: Subscription;
   private _changePasswordError: Subscription;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with save button.
   @Output() saved = new EventEmitter<any>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   @ViewChild('form') form: NgForm; // Reference to the  form

   // Height of the scroll panel inside this dialog.
   public scrollHeight: number;

   private strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.{}()?!@#%&/\\,><':;|_~`=+\-])(?=.{12,})");
   private mediumRegex = new RegExp("^(((?=.*[a-zA-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{12,})");

   constructor(private _windowService: BrowserWindowService, private _applicationStore: ApplicationStore) {

      //subscribe to the window resize event
      _windowService.height$.subscribe((value: number) => {
         this.scrollHeight = value - 180;
      });

      this.passwordStrength = {
         "float": "left",
         "width": "200px",
         "height": "25px",
         "padding": "2px",
         "padding-left": "40px",
         "background-color": "white",
         "color": "black"
      };
   }

   public ngOnInit() {
      this.username = this._applicationStore.authenticationStore.loggedInUser.fullname;
      this.subscribeToPasswordChange();
      this.subscribeToChangePasswordError();
   }

   public ngOnDestroy() {
      if (this._passwordChanged) {
         this._passwordChanged.unsubscribe();
      }

      if (this._changePasswordError) {
         this._changePasswordError.unsubscribe();
      }
   }

   // Checks whether the form is valid.
   public get formValid(): boolean {

      if (this.form.value.newPassword != this.form.value.newPassword2) {
         return false;
      }
      return this.form.valid;
   }

   public submitForm(form: NgForm) {
      this.onSave();
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

   // Called when the user clicks on the Save button.
   public onSave() {
      this.showError = false;
      this.errorMsg = '';

      if (this.validation()) {
         this._applicationStore.authenticationStore.changePassword(this.form.value.oldPassword, this.form.value.newPassword);
      } else {
         this.showError = true;
      }
   }

   public analyze(pasword) {
      if (this.strongRegex.test(pasword)) {
         this.passwordStrength["background-color"] = "green";
         this.passwordStrengthText = "Strong";
      } else if (this.mediumRegex.test(pasword)) {
         this.passwordStrength["background-color"] = "orange";
         this.passwordStrengthText = "Medium";
      } else {
         this.passwordStrength["background-color"] = "red";
         this.passwordStrengthText = "Weak";
      }
   }

   private validation() {

      if (!this.strongRegex.test(this.form.value.newPassword)) {
         this.errorMsg = "New password is not a strong password"
         return false;
      }

      if (this.form.value.newPassword != this.form.value.newPassword2) {
         this.errorMsg = "The Passwords you entered do not match"
         return false;
      }

      if (this.form.value.oldPassword == this.form.value.newPassword2) {
         this.errorMsg = "The Current password matches your new password."
         return false;
      }

      return this.form.valid;
   }

   private subscribeToPasswordChange() {
      this._passwordChanged =  this._applicationStore.authenticationStore.passwordChanged.subscribe(
         () => {
           this.display = false;
           this.saved.next();
         }
      );
   }

   private subscribeToChangePasswordError() {
      this._changePasswordError = this._applicationStore.authenticationStore.changePasswordError$.subscribe(
         (error: string) => {
            if (error) {
               this.showError = true;
               this.errorMsg = error;
            }
         }
      );
   }
}
