import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApplicationStore } from 'app/stores/application-store';
import { Subscription } from 'rxjs';

export class NewPasswordUser {
  username: string;
  existingPassword: string;
  password: string;
}

@Component({
  selector: 'callsmart-new-password',
  templateUrl: './new-password.component.html'
})
export class NewPasswordComponent implements OnInit, OnDestroy {

  public newPassword: string;
  public confirmPassword: string;
  public errorMsg: string = "";
  public showError: boolean = false;
  private _newPasswordError: Subscription;
  

  constructor(private _applicationStore: ApplicationStore) { }

  ngOnInit() {
    this.subscribeToNewPasswordError();
  }

  public ngOnDestroy() {
    if (this._newPasswordError) {
       this._newPasswordError.unsubscribe();
    }
 }

  public onNewPassword(form: NgForm) {

    this.showError = false;
    this.errorMsg = '';

    if (form.valid) {

      if (this.newPassword != this.confirmPassword) {
        this.errorMsg = "The Passwords you entered do not match"
        this.showError = true;
        return false;
     }

     this._applicationStore.authenticationStore.updatePasswordHistory(this.newPassword);
    }
 }

 private subscribeToNewPasswordError() {
  this._newPasswordError = this._applicationStore.authenticationStore.newPasswordError$.subscribe(
     (error: string) => {
        if (error) {
           this.showError = true;
           this.errorMsg = error;
        }
     }
  );
}

}
