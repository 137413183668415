import { throwError as observableThrowError, BehaviorSubject, Observable, from } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConvert } from 'json2typescript';

import { User } from 'app/models/user';
import { environment } from 'environments/environment';
import { Company } from 'app/models/company';
import { HeartBeatData } from 'app/models/heartBeatData';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';

@Injectable()
export class AuthService {

   // user logine in
   private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
   public user$: Observable<User> = this._user.asObservable();

   private user: User;
   public cognitoUser: any;
   acountUrl = `${environment.baseUrl}api/account`;

   constructor(private http: HttpClient) { }

   public get loggedInUser() {
      return this._user.getValue();
   }
   
   public get loggedInCognitoUser() {
      if(this.cognitoUser == null){
         this.loadCognitoUser() ;
      }
      return this.cognitoUser;
   }
   
   public loadCognitoUser()  {
      var cognitoUser =  localStorage.getItem("cognitoUser");
      if(cognitoUser != null){
         this.cognitoUser = JSON.parse(cognitoUser) as CognitoUser;
         return;
      }

      var email =  localStorage.getItem("userName");
      this.cognitoUser = this.createCognitoUser(email);
   }

   public createCognitoUser(email: string): CognitoUser {
      let poolData = { UserPoolId: environment.cognitoUserPoolId, ClientId: environment.cognitoAppClientId };
      let userPool = new CognitoUserPool(poolData);

      if(email != null){
         let userData = { Username: email, Pool: userPool };
         return new CognitoUser(userData);
      }
      
      return userPool.getCurrentUser();
   }

   public login(email: string, password: string) {
      return this.http.post<User>(`${this.acountUrl}/Login`, { 'Email': email, 'Password': password })
         .pipe(catchError(error => observableThrowError(error)));
   }

   
   public cognitoLogin(email: string, password: string): Observable<any> {

      let authenticationDetails = new AuthenticationDetails({ Username: email, Password: password });
      this.cognitoUser = this.createCognitoUser(email);

      const pendingSignIn = new Promise((resolve, reject) => {
         this.cognitoUser.authenticateUser(authenticationDetails,
            this.authCallbacks(this.cognitoUser, value => { resolve(value); }, error => { reject(error); }));
      });

      return from(pendingSignIn).pipe(retry(3), catchError(error => observableThrowError(error)));
   }
   
   public completeNewPassword(password: string): Observable<CognitoUser | any> {
		
		const completePassword = new Promise((resolve, reject) => {
			this.cognitoUser.completeNewPasswordChallenge(password, {},
				this.authCallbacks(this.cognitoUser, value => { resolve(value); }, error => { reject(error); }));
		});

		return from(completePassword).pipe(retry(3), catchError(error => observableThrowError(error)));
	}

   public validateMFACode(confirmationCode: string): Observable<CognitoUser | any> {
		
		const sendMFACode = new Promise((resolve, reject) => {
			this.cognitoUser.sendMFACode(confirmationCode,
            this.authCallbacks(this.cognitoUser, value => { resolve(value); },	error => { reject(error); }));
		});

		return from(sendMFACode).pipe(retry(3), catchError(error => observableThrowError(error)));
	}
   
   public expiredLogin(email: string, oldpassword: string, newpassword: string, accessToken: string) {
      return this.http.post<User>(`${this.acountUrl}/ExpiredLogin`, 
         { 'Email': email, 'OldPassword': oldpassword, 'NewPassword': newpassword,'VerificationCode': accessToken })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public changePassword(email: string, oldpassword: string, newpassword: string, verificationCode: string) {
      return this.http.post(`${this.acountUrl}/ChangePassword`,
         { 'Email': email, 'OldPassword': oldpassword, 'NewPassword': newpassword, 'VerificationCode': verificationCode })
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public updatePasswordHistory(email: string, newpassword: string) {
      return this.http.post(`${this.acountUrl}/updatePasswordHistory`,
         { 'Email': email, 'NewPassword': newpassword })
         .pipe(catchError(error => observableThrowError(error)));
   }

   public setUserHeartBeat(userId: number, data: HeartBeatData) {
        return this.http.post(`${this.acountUrl}/users/${userId}/heartbeat`, data)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error))); 
   }

   public clearUserProjectHeartBeat(userId: number, data: HeartBeatData) {
      return this.http.post(`${this.acountUrl}/users/${userId}/project/${data.projectId}/heartbeat`, data)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public resetPassword(email: string, newPassword: string, verificationCode: string) {
      return this.http.post(`${this.acountUrl}/resetPassword`, 
         { 'Email': email, 'NewPassword': newPassword, 'VerificationCode': verificationCode  })
         .pipe(retry(1)).pipe(catchError(error => observableThrowError(error)));
   }

   public doesCognitoUserExists(email: string){
      return this.http.post(`${this.acountUrl}/userExists`, { 'Email': email })
      .pipe(retry(3)).pipe(catchError(error => observableThrowError(error)));
   }

   public cognitoResetPassword(email: string) {
      this.cognitoUser = this.createCognitoUser(email);

      const resetPassword = new Promise((resolve, reject) => {
         this.cognitoUser.forgotPassword({onSuccess: data => { },
         onFailure: err =>{ reject(err);  },
         inputVerificationCode: data =>{ resolve(data);}});
      });

      return from(resetPassword).pipe(retry(3), catchError(error => observableThrowError(error)));
   }

   public confirmPasswordAfterForgotPassword(username: string, verificationCode: string, newPassword: string ) {
      const confirmPasswordChange = new Promise((resolve, reject) => {
         this.cognitoUser.confirmPassword(verificationCode, newPassword, {
            onSuccess:() => {
              resolve(null);
            },
            onFailure: err =>{ reject(err); }})});

      return from(confirmPasswordChange).pipe(retry(3), catchError(error => observableThrowError(error)));
   }

   public logout() {
      this.user = null;

      return this.http.get(`${this.acountUrl}/Logout`)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public cognitoLogout() {
      const userLogOut = new Promise((resolve, reject) => {
         this.cognitoUser.globalSignOut({
            onSuccess: msg => { resolve(msg); },
            onFailure: err =>{ reject(err); }})
      });

      return from(userLogOut).pipe(retry(3), catchError(error => observableThrowError(error)));
   }

   /***********************************************************
    * Retrieving the current user from local storage.
    * Use case 16.
    * https://www.npmjs.com/package/amazon-cognito-identity-js
   *********************************************************** */
   public getUserFromCookie(): Observable<any> {
      var cognitoUser = this.createCognitoUser(null);

      const readCognitoCookie = new Promise((resolve, reject) => {
         const isSessionValid = cognitoUser != null ? cognitoUser.getSession((err, session) => {
            if (err) {
               reject(err);
               return false;
            }

            if (session === undefined) {
               this.cognitoUser.signOut();
               this.cognitoUser = null;
               return false;
            }

            if (session.isValid()) {
               this.cognitoUser = cognitoUser;
               return true;
            }

            return false;
         }) : false;

         resolve(isSessionValid);
      });

      return from(readCognitoCookie).pipe(retry(3), catchError(error => observableThrowError(error)));
   }

   public isAuthenticated() {
      return this.http.get<User>(`${this.acountUrl}/isAuthenticated`)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)))
   }

   public setUser(user: User) {
      this._user.next(user);
   }


   public getUserCompany(userId: number): Observable<any> {
      let url: string = `${this.acountUrl}/users/${userId}/company`;
      return this.http.get(url)
         .pipe(retry(3)).pipe(
            map(
               (response: any) => {
                  let companyObjects: Company;
                  let jsonConvert: JsonConvert = new JsonConvert();

                  //Deserialise to Typescript objects
                  try {
                     companyObjects = jsonConvert.deserialize(response, Company);
                  }
                  catch (e) {
                     return observableThrowError((<Error>e).message);
                  }
                  return companyObjects;
               }),
            catchError(error => observableThrowError(error)));
   }

   	
	private authCallbacks(user: CognitoUser, resolve: (value?: CognitoUser | any) => void,
   reject: (value?: any) => void	) {
   const that = this;
   return {
      onSuccess: async session => {
         delete user['challengeName'];
         delete user['challengeParam'];
         localStorage.removeItem("cognitoUser");
         localStorage.setItem("cognitoUser", JSON.stringify(user));
         user.setSignInUserSession(session);
         resolve(user);
      },
      onFailure: err => {
         reject(err);
      },
      customChallenge: challengeParam => {
         user['challengeName'] = 'CUSTOM_CHALLENGE';
         user['challengeParam'] = challengeParam;
         resolve(user);
      },
      mfaRequired: (challengeName, challengeParam) => {
         user['challengeName'] = challengeName;
         user['challengeParam'] = challengeParam;
         resolve(user);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
         user['challengeName'] = 'NEW_PASSWORD_REQUIRED';
         user['challengeParam'] = userAttributes.email;
         resolve(user);
      }
   };
}
}
