

<div class="container">
   <div *ngIf="inMaintenance">
      <marquee behavior="scroll" direction="left" scrollamount="20" class="login-maintenance" bgcolor="#6954c3">{{title}}</marquee>
   </div>
   <div class="login-position">
      <div class="login-callsmart-logo">
         <img src="./assets/icons/CS_Logo_large_OnBlack.png" alt="CallSmart">
      </div>

      <div class="login-container">
         <div class="form-box">
            <form (ngSubmit)="onSignin(f)" #f="ngForm" autocomplete="off">

               <div class="form-group row">
                  <label class="control-label">Log in</label>
               </div>

               <div class="form-group row">
                  <input type="email" id="email" name="email" placeholder="Email" 
                  required pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"                                                                                             
                     #loginEmail="ngModel" [(ngModel)]="email" class="form-control cs-form-control">
                  <div [hidden]="loginEmail.valid || loginEmail.untouched">
                     <div *ngIf="loginEmail.errors && loginEmail.errors.required">
                        Email is required
                     </div>
                     <div *ngIf="loginEmail.errors && loginEmail.errors.pattern">
                        Email is invalid
                     </div>
                  </div>
               </div>

               <div class="form-group row">
                  <input type="password" id="password" name="password" placeholder="Password" 
                  required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?!@#%&/\\,><':;|_~`=+\- ])(.{12,})"
                  [(ngModel)]="password" #loginPassword="ngModel"
                     class="form-control cs-form-control">
                  <span *ngIf="loginPassword.touched && loginPassword.errors">
                     <label *ngIf="loginPassword.errors.required">Please insert your password</label>
                     <label *ngIf="loginPassword.errors.pattern">Invalid password</label>
                  </span>
               </div>
               <div class="form-group row">
                  <div class="form-group-chkbox" style="float:left" *ngIf="!inMaintenance">
                     <a style="cursor: pointer;" [routerLink]="['/password-reset']">
                        <div class="pull-right">Having trouble logging in?</div>
                     </a>
                  </div>

                  <button class="btn cs-btn-primary-inverse" style="float:right; width:150px" type="submit">Log in</button>

               </div>
            </form>
            <br>

            <div class="alert alert-danger fade in" *ngIf="showError">
               <strong>Error! </strong>{{errorMessage}}
            </div>
         </div>

         <div style="position: absolute;font-size: 16px;left: 4px;margin-top: 34px;">
            <a style="cursor: pointer;" target="_blank" [routerLink]="['/legal']">
               Legal
            </a>
         </div>

         <div class="login-caci-logo">
            <img class="caci-logo" src="./assets/icons/CACI_Logo_White.png" alt="CACI">
         </div>
      </div>

   </div>
</div>
