import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { User } from 'app/models/user';
import { ApplicationStore } from 'app/stores/application-store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'callsmart-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  // this component will now work similar to that of login component 
  // it is a combination of login and change password components
  //private _userForgotPassword_subscription: Subscription;
  private _loginError_subscription: Subscription;

  // Determines the visibility of the error message.
  public showError: boolean = false;

  // The error message to display.
  public errorMessage: string;

  public email: string;
  public CodeDeliveryDetails: any;
  public verificationCode: string;
  public newPassword: string;
  public confirmPassword: string;

  constructor(private _applicationStore: ApplicationStore) { }
  
  ngOnInit() {
    this.CodeDeliveryDetails = this._applicationStore.authenticationStore.CodeDeliveryDetails;
    this.email = this.CodeDeliveryDetails['username'];
   
    this.subscribeToLoginError();
  }

  ngOnDestroy(): void {
   if (this._loginError_subscription) {
      this._loginError_subscription.unsubscribe();
   }
  }

  private subscribeToLoginError() {
    this._loginError_subscription = this._applicationStore.authenticationStore.loginError$.subscribe(
       (error: string) => {
          if (error) {
             this.showError = true;
             this.errorMessage = error;
             ;
          }
       }
    );
  }

  public onConfirmNewPassword(form: NgForm){
    if (form.valid) {

      if (this.newPassword != this.confirmPassword) {
        this.errorMessage = "The Passwords you entered do not match"
        this.showError = true;
        return false;
     }

      this._applicationStore.authenticationStore.resetPassword(this.email, this.newPassword, this.verificationCode);
    }
  }

}
