import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { ApplicationStore } from "app/stores/application-store";
import { Observable } from "rxjs";

/*
  this is a guard service which is injectable.
  it does not take the http service as it is not used to communicate with the web api.
  this to be used as a routing guard to prevent the user from navigating to pages without logging in
*/

@Injectable()
export class NewPasswordGuardService implements CanActivate {

    constructor(private _applicationStore: ApplicationStore) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        // cognito user has logged in
        if (this._applicationStore.authenticationStore.cognitoUser != null) {
            if (this._applicationStore.authenticationStore.cognitoUser.challengeName
                    && this._applicationStore.authenticationStore.cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                return true;
            }
        }

        return false;
    }
}