import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//ng prime
import {
   DataTableModule,
   SharedModule,
   SelectItem,
   MultiSelectModule,
   TreeTableModule
} from 'primeng/primeng';
import { ScheduleModule } from 'primeng/components/schedule/schedule';
import { DialogModule } from 'primeng/components/dialog/dialog';
import { CalendarModule } from 'primeng/components/calendar/calendar';
import { CheckboxModule } from 'primeng/components/checkbox/checkbox';
import { SelectButtonModule } from 'primeng/components/selectbutton/selectbutton';
import { DropdownModule } from 'primeng/primeng';

// scroll bar component
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
   suppressScrollX: true
};

// 3rd party split panel
import { SplitPaneModule } from 'ng2-split-pane/lib/ng2-split-pane';

// google map ng primes gmap
import {
   GMapModule,
   SliderModule,
   InputSwitchModule,
   MenuModule,
   StepsModule,
   AccordionModule
} from 'primeng/primeng';

//services
import { AuthService } from './services/auth.service';
import { CallerService } from './services/caller.service';
import { CallpointService } from './services/callpoint.service';
import { ProjectService } from './services/project.service';
import { ScheduleService } from './services/schedule.service';
import { VisitsService } from './services/visits.service';
import { SysAdminService } from './services/sys-admin.service';
import { AuditService } from 'app/services/audit-service';

//routing
import { AppRoutingModule } from './app-routing-module';

//guards
import { AuthGuardService } from './services/auth-guard.service';
import { AdminGuardService } from './services/admin-guard.service';
import { AllAdminGuardService } from 'app/services/all-admin-guard';
import { CompanyAdminGuardService } from 'app/services/company-admin-guard.service';
import { SmsMfaVerificationGuardService } from './services/sms-mfa-verification-guard.service';
import { NewPasswordGuardService } from './services/new-password-guard.service';

//components
import { AppComponent } from './app.component';
import { CallersWorkspaceComponent } from './callers-workspace/callers-workspace.component';
import { CallersListComponent } from './callers-workspace/callers-list/callers-list.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { CallpointWorkspaceComponent } from './callpoint-workspace/callpoint-workspace.component';
import { DiaryWorkspaceComponent } from './diary-workspace/diary-workspace.component';
import { CallpointsListComponent } from './callpoint-workspace/callpoints-list/callpoints-list.component';

//import { CalendarComponent } from './diary-workspace/calendar/calendar.component';
import { CalendarComponent } from './shared/calendar/calendar.component';
import { LoginComponent } from './login/login.component';
import { SysAdminWorkspaceComponent } from './sys-admin-workspace/sys-admin-workspace.component';
import { DashboardWorkspaceComponent } from './dashboard-workspace/dashboard-workspace.component';
import { DashboardComponent } from './dashboard-workspace/dashboard/dashboard.component';
import { StyleGuideComponent } from 'app/styleguide-workspace/style-guide.component';
import { ScheduleWorkspaceComponent } from './schedule-workspace/schedule-workspace.component';
import { ScheduleTestWorkspaceComponent } from './schedule-test-workspace/schedule-test-workspace.component';
import { ScheduleTestContextualPanelComponent } from './schedule-test-workspace/schedule-test-contextual-panel/schedule-test-contextual-panel.component';
import { GoogleMapComponent } from './shared/google-map/google-map.component';
import { CallerSettingsSummaryComponent } from './shared/caller-settings-summary/caller-settings-summary.component';
import { CallerDaySummaryComponent } from './shared/caller-day-summary/caller-day-summary.component';
import { CsDropdownModule } from 'app/shared/dropdown/dropdown.module';
import { VisitsWorkspaceComponent } from './visits-workspace/visits-workspace.component';
import { VisitsListComponent } from './visits-workspace/visits-list/visits-list.component';
import { CallerVisitsSummaryComponent } from './shared/caller-visits-summary/caller-visits-summary.component';
import { CsInputSwitchModule } from 'app/shared/input-switch/input-switch.module';
import { CallerCarouselComponent } from './shared/caller-carousel/caller-carousel.component';
import { TooltipModule } from 'primeng/components/tooltip/tooltip';
import { MiniDashComponent } from 'app/shared/mini-dash/mini-dash.component';
import { CSMultiSelectModule } from 'app/shared/multi-select/multi-select.module';
import { CallpointSummaryComponent } from './shared/callpoint-summary/callpoint-summary.component';
import { CSGrowlModule } from 'app/shared/growl/growl.module';
import { WizardTestComponent } from 'app/wizard-workspace/wizard-test/wizard-test.component';
import { WizardModule } from 'app/shared/wizard/wizard.module';
import { NewProjectWorkspaceComponent } from './new-project-workspace/new-project-workspace.component';
import { NewProjectSettingsComponent } from './new-project-workspace/new-project-settings/new-project-settings.component';
import { NewProjectSummaryComponent } from './new-project-workspace/new-project-summary/new-project-summary.component';
import { NewProjectCallersComponent } from './new-project-workspace/new-project-callers/new-project-callers.component';
import { NewProjectCallpointsComponent } from './new-project-workspace/new-project-callpoints/new-project-callpoints.component';

//stores
import { ApplicationStore } from './stores/application-store';
import { VisitsStore } from 'app/stores/visits-store';
import { NotificationsStore } from 'app/stores/notifications-store';
import { ScheduleStore } from 'app/stores/schedule-store';
import { MapsStore } from 'app/stores/maps-store';
import { CallpointsStore } from 'app/stores/callpoints-store';
import { ProjectsStore } from 'app/stores/projects-store';
import { UserCallerSettingsStore } from 'app/stores/user-caller-settings-store';
import { UserCallpointSettingsStore } from 'app/stores/user-callpoint-settings-store';
import { UserProjectSettingsStore } from 'app/stores/user-project-settings-store';
import { CallersStore } from 'app/stores/callers-store';
import { AuthenticationStore } from 'app/stores/authentication-store';
import { AlertStore } from 'app/stores/alert-store';
import { ErrorStore } from 'app/stores/error-store';
import { DefaultDayCombinationsStore } from 'app/stores/default-day-combinations-store';
import { SysAdminStore } from 'app/stores/sys-admin-store';
import { AuditStore } from 'app/stores/audit-store';
import { SpinnerStore } from 'app/stores/spinner-store';
import { UiStore } from 'app/stores/ui-store';

// pipes
import { DistanceUnits } from 'app/pipes/distance-units.pipe';
import { DistanceUnitsCost } from 'app/pipes/distance-unit-cost.pipe';
import { TruncateString } from 'app/pipes/truncate-string.pipe';
import { CarouselNameTruncation } from 'app/pipes/carousel-name-truncation.pipe';

// dialogs
import { DeleteProjectDialogComponent } from './open-project-workspace/delete-project-dialog/delete-project-dialog.component';
import { ExportDialogComponent } from './dashboard-workspace/export-dialog/export-dialog.component';
import { EditCallerSettingsDialogComponent } from 'app/shared/edit-caller-settings-dialog/edit-caller-settings-dialog.component';
import { ConfirmDialogModule } from 'primeng/components/confirmdialog/confirmdialog';
import { ErrorDialogComponent } from './shared/error-dialog/error-dialog.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { NewFolderDialogComponent } from './shared/new-folder-dialog/new-folder-dialog.component';
import { EditProjectSettingsDialogComponent } from 'app/new-project-workspace/settings/edit-project-settings-dialog/edit-project-settings-dialog.component';
import { EditProjectCallerSettingsDialogComponent } from 'app/new-project-workspace/settings/edit-project-caller-settings-dialog/edit-project-caller-settings-dialog.component';
import { EditCallpointSettingsDialogComponent } from 'app/shared/edit-callpoint-settings-dialog/edit-callpoint-settings-dialog.component';
import { EditProjectCallpointSettingsDialogComponent } from 'app/new-project-workspace/settings/edit-project-callpoint-settings-dialog/edit-project-callpoint-settings-dialog.component';
import { DeleteUserDialogComponent } from './admin-workspace/delete-user-dialog/delete-user-dialog.component';
import { DeleteCompanyDialogComponent } from './admin-workspace/delete-company--dialog/delete-company--dialog.component';
import { AddEditUserDialogComponent } from './admin-workspace/add-edit-user-dialog/add-edit-user-dialog.component';
import { AddEditCompanyDialogComponent } from './admin-workspace/add-edit-company-dialog/add-edit-company-dialog.component';
import { AddEditTravelModelDialogComponent } from './admin-workspace/add-edit-travel-model-dialog/add-edit-travel-model-dialog.component';
import { DeleteTravelModelDialogComponent } from './admin-workspace/delete-travel-model-dialog/delete-travel-model-dialog.component';
import { CopyProjectDialogComponent } from './open-project-workspace/copy-project-dialog/copy-project-dialog.component';
import { RollProjectForwardDialogComponent } from './open-project-workspace/roll-project-forward-dialog/roll-project-forward-dialog.component';
import { RenameProjectDialogComponent } from './open-project-workspace/rename-project-dialog/rename-project-dialog.component';

// other
import { FileUploadModule } from 'primeng/components/fileupload/fileupload';
import { NotificationWorkspaceComponent } from './notification-workspace/notification-workspace.component';
import { DateRangeSliderModule } from 'app/shared/date-range-slider/date-range-slider.module';
import { RangeBarModule } from 'app/shared/range-bar/range-bar.module';
import { EditCallerSettingsComponent } from 'app/shared/edit-caller-settings/edit-caller-settings.component';
import { SettingsWorkspaceComponent } from 'app/settings-workspace/settings-workspace.component';
import { CurrencySymbolPipe } from 'app/shared/currency-symbol/currency-symbol.pipe';
import { RangeSliderModule } from 'app/shared/range-slider/range-slider.module';
import { SettingsService } from 'app/services/settings.service';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { ProjectSettingsWorkspaceComponent } from './project-settings-workspace/project-settings-workspace.component';
import { ClockTimePickerComponent } from './shared/clock-time-picker/clock-time-picker.component';
import { ProjectCalendarDialogComponent } from './project-settings-workspace/project-calendar-dialog/project-calendar-dialog.component';
import { DynamicModule } from 'ng-dynamic-component';
import { OpenProjectWorkspaceComponent } from './open-project-workspace/open-project-workspace.component';
import { ProjectListComponent } from './open-project-workspace/project-list/project-list.component';
import { EditCallpointSettingsComponent } from 'app/shared/edit-callpoint-settings/edit-callpoint-settings.component';
import { ProjectPropertiesEventComponent } from 'app/project-settings-workspace/project-properties-event/project-properties-event.component';
import { ProjectPropertiesCallpointsComponent } from 'app/project-settings-workspace/project-properties-callpoints/project-properties-callpoints.component';
import { UserCallerSettingsComponent } from 'app/settings-workspace/user-caller-settings.component';
import { UserProjectSettingsComponent } from 'app/settings-workspace/user-project-settings.component';
import { UserCallpointSettingsComponent } from 'app/settings-workspace/user-callpoint-settings.component';
import { ProjectPropertiesCallersComponent } from 'app/project-settings-workspace/project-properties-callers/project-properties-callers.component';

import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { EditProjectSettingsComponent } from 'app/shared/edit-project-settings/edit-project-settings.component';
import { ProjectPropertiesSettingsComponent } from 'app/project-settings-workspace/project-properties-settings/project-properties-settings.component';

import { NewProjectEventsComponent } from './new-project-workspace/new-project-events/new-project-events.component';
import { NewProjectSettingsSettingComponent } from 'app/new-project-workspace/settings/new-project-settings-setting.component';
import { NewProjectCallerSettingsComponent } from 'app/new-project-workspace/settings/new-project-caller-settings.component';
import { NewProjectCallpointSettingsComponent } from 'app/new-project-workspace/settings/new-project-callpoint-settings.component';

import { ChangePasswordComponent } from './change-password/change-password.component';

import { MultiSelectedCallersComponent } from 'app/callers-workspace/multi-selected-callers/multi-selected-callers.component';

import { MultiSelectedCallpointsComponent } from 'app/callpoint-workspace/multi-selected-callpoints/multi-selected-callpoints.component';
import { DayCombinationComponent } from './shared/day-combination/day-combination.component';
import { EditCalendarDialogComponent } from './shared/edit-calendar-dialog/edit-calendar-dialog.component';
import { CloseCalendarDaysComponent } from './shared/close-calendar-days/close-calendar-days.component';
import { LogoutComponent } from 'app/shared/logout/logout.component';
import { AdminWorkspaceComponent } from './admin-workspace/admin-workspace.component';
import { CompanyListComponent } from './admin-workspace/company-list/company-list.component';
import { UserListComponent } from './admin-workspace/user-list/user-list.component';
import { AdminCompanyPanelComponent } from './admin-workspace/admin-company-panel/admin-company-panel.component';
import { AdminUserPanelComponent } from './admin-workspace/admin-user-panel/admin-user-panel.component';
import { TravelModelListComponent } from './admin-workspace/travel-model-list/travel-model-list.component';
import { AdminTravelModelPanelComponent } from './admin-workspace/admin-travel-model-panel/admin-travel-model-panel.component';
import { AdminAuditPanelComponent } from './admin-workspace/admin-audit-panel/admin-audit-panel.component';
import { ProjectStatusWorkspaceComponent } from './project-status-workspace/project-status-workspace.component';
import { ErrorsComponent } from 'app/shared/errors-component/errors.component';
import { ErrorHandlerService } from 'app/services/error-handler.service';
import { ContextualPanelComponent } from 'app/shared/contextual-panel/contextual-panel.component';
import { ContextualPanelStore } from './stores/contextual-panel-store';
import { FocusDirective } from 'app/directives/focus-directive';
import { ImportDialogComponent } from 'app/dashboard-workspace/import-dialog/import-dialog.component';
import { SpinnerDialogComponent } from 'app/shared/spinner-dialog/spinner-dialog.component';

import { ImportDataWorkspaceComponent } from 'app/import-data-workspace/import-data-workspace.component';
import { ImportDataSummaryComponent } from 'app/import-data-workspace/import-data-summary/import-data-summary.component';
import { ImportDataStore } from 'app/stores/import-data-store';
import { VisitsListV2Component } from './visits-workspace/visits-list-v2/visits-list-v2.component';
import { CloseProjectComponent } from './shared/close-project/close-project.component';
import { ReassignCallpointDialogComponent } from './shared/reassign-callpoint-dialog/reassign-callpoint-dialog.component';
import { HelpDialogComponent } from 'app/shared/help-dialog/help-dialog.component';
import { LegalComponent } from './login/legal/legal.component';
import { MomentDate } from './pipes/moment-date.pipe';
import { TravelModelDialogComponent } from './dashboard-workspace/travel-model-dialog/travel-model-dialog.component';
import { EditCallpointsDialogComponent } from './shared/edit-callpoints-dialog/edit-callpoints-dialog.component';
import { SwapDaysDialogComponent } from './shared/swap-days-dialog/swap-days-dialog.component';
import { WorkspaceNavigationLogic } from './shared/optimisation-logic/workspace-navigation-logic';
import { RouteInfillDialogComponent } from './shared/route-infill-dialog/route-infill-dialog.component';
import { AdminProjectPanelComponent } from './admin-workspace/admin-project-panel/admin-project-panel.component';
import { AdminProjectListComponent } from './admin-workspace/admin-project-list/admin-project-list.component';
import { CloneProjectDialogComponent } from './admin-workspace/clone-project-dialog/clone-project-dialog.component';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './interceptors/app.interceptors';
import { ExpiredPasswordComponent } from './expired-password/expired-password.component';
import { NgIdleModule } from '@ng-idle/core';
import { AdminMaintenancePanelComponent } from './admin-workspace/admin-maintenance-panel/admin-maintenance-panel.component';
import { NewPasswordComponent } from './login/new-password/new-password.component';
import { SmsMfaVerificationComponent } from './login/sms-mfa-verification/sms-mfa-verification.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';

@NgModule({
   declarations: [
      AppComponent,
      SettingsWorkspaceComponent,
      UserCallerSettingsComponent,
      UserProjectSettingsComponent,
      UserCallpointSettingsComponent,
      CurrencySymbolPipe,
      CallersWorkspaceComponent,
      CallersListComponent,
      NavbarComponent,
      CallpointWorkspaceComponent,
      DiaryWorkspaceComponent,
      CallpointsListComponent,
      CalendarComponent,
      LoginComponent,
      SysAdminWorkspaceComponent,
      DashboardWorkspaceComponent,
      DashboardComponent,
      StyleGuideComponent,
      ScheduleWorkspaceComponent,
      ScheduleTestWorkspaceComponent,
      ScheduleTestContextualPanelComponent,
      GoogleMapComponent,
      CallerSettingsSummaryComponent,
      CallerDaySummaryComponent,
      VisitsWorkspaceComponent,
      VisitsListComponent,
      CallerVisitsSummaryComponent,
      CallerCarouselComponent,
      MiniDashComponent,
      CallpointSummaryComponent,
      WizardTestComponent,
      NewProjectWorkspaceComponent,
      NewProjectSettingsComponent,
      NewProjectSummaryComponent,
      NewProjectCallersComponent,
      NewProjectCallpointsComponent,
      NotificationWorkspaceComponent,
      EditCallerSettingsDialogComponent,
      EditCallerSettingsComponent,
      ProjectSettingsWorkspaceComponent,
      EditProjectSettingsComponent,
      ClockTimePickerComponent,
      ProjectCalendarDialogComponent,
      OpenProjectWorkspaceComponent,
      ProjectListComponent,
      EditCallpointSettingsComponent,
      ProjectPropertiesEventComponent,
      ProjectPropertiesCallpointsComponent,
      ProjectPropertiesCallersComponent,
      ErrorDialogComponent,
      ProjectPropertiesSettingsComponent,
      AlertDialogComponent,
      NewProjectEventsComponent,
      NewProjectSettingsSettingComponent,
      EditProjectSettingsDialogComponent,
      EditProjectCallerSettingsDialogComponent,
      NewProjectCallerSettingsComponent,
      NewProjectCallpointSettingsComponent,
      EditProjectCallpointSettingsDialogComponent,
      ChangePasswordComponent,
      EditCallpointSettingsDialogComponent,
      MultiSelectedCallersComponent,
      NewFolderDialogComponent,
      MultiSelectedCallpointsComponent,
      DayCombinationComponent,
      DeleteProjectDialogComponent,
      DistanceUnits,
      DistanceUnitsCost,
      TruncateString,
      CarouselNameTruncation,
      ExportDialogComponent,
      EditCalendarDialogComponent,
      CloseCalendarDaysComponent,
      LogoutComponent,
      LogoutComponent,
      AdminWorkspaceComponent,
      CompanyListComponent,
      UserListComponent,
      AdminCompanyPanelComponent,
      AdminUserPanelComponent,
      DeleteUserDialogComponent,
      DeleteCompanyDialogComponent,
      AddEditUserDialogComponent,
      AddEditCompanyDialogComponent,
      TravelModelListComponent,
      AdminTravelModelPanelComponent,
      AddEditTravelModelDialogComponent,
      DeleteTravelModelDialogComponent,
      AdminAuditPanelComponent,
      ProjectStatusWorkspaceComponent,
      AdminAuditPanelComponent,
      ContextualPanelComponent,
      ErrorsComponent,
      CopyProjectDialogComponent,
      FocusDirective,
      RollProjectForwardDialogComponent,
      ImportDialogComponent,
      SpinnerDialogComponent,
      ImportDataWorkspaceComponent,
      ImportDataSummaryComponent,
      RenameProjectDialogComponent,
      VisitsListV2Component,
      CloseProjectComponent,
      ReassignCallpointDialogComponent,
      HelpDialogComponent,
      LegalComponent,
      MomentDate,
      TravelModelDialogComponent,
      EditCallpointsDialogComponent,
      SwapDaysDialogComponent,
      RouteInfillDialogComponent,
      AdminProjectPanelComponent,
      AdminProjectListComponent,
      CloneProjectDialogComponent,
      ExpiredPasswordComponent,
      AdminMaintenancePanelComponent,
      ExpiredPasswordComponent,
      NewPasswordComponent,
      SmsMfaVerificationComponent,
      PasswordResetComponent,
      ForgotPasswordComponent
   ],
   imports: [
      BrowserModule,
      FormsModule,
      HttpModule,
      HttpClientModule,
      DataTableModule,
      SharedModule,
      DialogModule,
      ScheduleModule,
      CalendarModule,
      CheckboxModule,
      SelectButtonModule,
      BrowserAnimationsModule,
      MultiSelectModule,
      GMapModule,
      AppRoutingModule,
      PerfectScrollbarModule,
      DropdownModule,
      SplitPaneModule,
      CsDropdownModule,
      TreeTableModule,
      CsInputSwitchModule,
      CSMultiSelectModule,
      TooltipModule,
      CSGrowlModule,
      WizardModule,
      FileUploadModule,
      DateRangeSliderModule,
      RangeBarModule,
      SliderModule,
      InputSwitchModule,
      MenuModule,
      RangeSliderModule,
      DropdownModule,
      CsDropdownModule,
      CsInputSwitchModule,
      CheckboxModule,
      DynamicModule.withComponents([
         EditCallerSettingsDialogComponent,
         ProjectCalendarDialogComponent,
         ErrorDialogComponent,
         AlertDialogComponent,
         EditProjectSettingsDialogComponent,
         EditProjectCallerSettingsDialogComponent,
         EditProjectCallpointSettingsDialogComponent,
         ChangePasswordComponent,
         EditCallpointSettingsDialogComponent,
         NewFolderDialogComponent,
         DeleteProjectDialogComponent,
         ExportDialogComponent,
         EditCalendarDialogComponent,
         DeleteCompanyDialogComponent,
         DeleteUserDialogComponent,
         AddEditCompanyDialogComponent,
         AddEditUserDialogComponent,
         DeleteTravelModelDialogComponent,
         AddEditTravelModelDialogComponent,
         CopyProjectDialogComponent,
         RollProjectForwardDialogComponent,
         ImportDialogComponent,
         SpinnerDialogComponent,
         RenameProjectDialogComponent,
         ReassignCallpointDialogComponent,
         HelpDialogComponent,
         TravelModelDialogComponent,
         EditCallpointsDialogComponent,
         SwapDaysDialogComponent,
         RouteInfillDialogComponent,
         CloneProjectDialogComponent
      ]),
      StepsModule,
      ConfirmDialogModule,
      AccordionModule,
      NgIdleModule.forRoot()
   ],
   providers: [
      httpInterceptorProviders,
      ApplicationStore,
      ProjectService,
      CallerService,
      CallpointService,
      AuthService,
      AuthGuardService,
      ScheduleService,
      VisitsService,
      VisitsStore,
      NotificationsStore,
      ScheduleStore,
      MapsStore,
      CallpointsStore,
      ProjectsStore,
      UserCallerSettingsStore,
      UserCallpointSettingsStore,
      UserProjectSettingsStore,
      CallersStore,
      AuthenticationStore,
      SettingsService,
      BrowserWindowService,
      ConfirmationService,
      AlertStore,
      ErrorStore,
      DefaultDayCombinationsStore,
      AdminGuardService,
      SysAdminService,
      SysAdminStore,
      AllAdminGuardService,
      CompanyAdminGuardService,
      AuditStore,
      AuditService,
      ErrorHandlerService,
      ContextualPanelStore,
      SpinnerStore,
      ImportDataStore,
      UiStore,
      WorkspaceNavigationLogic,
      NewPasswordGuardService,
      SmsMfaVerificationGuardService,
      [
         {
           provide: PERFECT_SCROLLBAR_CONFIG,
           useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
         }
       ]
   ],
   bootstrap: [AppComponent]
})
export class AppModule {}
