<form novalidate #callersForm="ngForm" class="form-horizontal" autocomplete="off">
   <div class="panel">
      <div class="top-content-border bottom-content-border" style="margin-top: -1px;">
         <perfect-scrollbar [ngStyle]="{'height.px': componentHeight-33}">
            <div class="row">
               <div class="cs-wizard-step-panel col-sm-12">
                  <div class="panel-body" style="padding: 15px 15px 0px 15px">
                     <h6>Import data</h6>
                     <div class="row">
                        <div class="col-xs-12">
                           <label class="control-label">Imported data must use the correct template.</label>
                           <a class="white-link-underline control-link" [href]="dataTemplate"
                              download="DataTemplates.xlsx" style="margin: 0px 0px 0px 15px;">Download template</a>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-xs-12">
                           <label class="control-label">Allowed file delimiters: comma, semicolon, tab.</label>
                        </div>
                     </div>

                     <div class="row">
                        <div style="margin:15px 0px 15px 0px">
                           <p-fileUpload name="myfile[]" [url]="uploadUrl" #fileUpload withCredentials=true [auto]="false"
                              [multiple]=false (onSelect)="onSelect($event)" [showUploadButton]=false [showCancelButton]=false
                              previewWidth="0" chooseLabel="Select file to upload"  accept=".csv,.txt" (onUpload)="onUpload()"
                              (mouseover)="mouseOver()" (onError)="onUploadError($event)" 
                              (onBeforeSend)="onBeforeSend($event)"></p-fileUpload>
                        </div>
                     </div>

                     <div class="row" >
                        <div style="margin:0px 0px 10px 20px">
                           <a class="file-selected-outer" style="margin-bottom: 10px; " *ngIf="selectedFileName.length > 0">
                              <div class="file-selected-count-outer">

                                 <span [ngStyle]="{'color': validationFailed ? '#e90000' : '#ffffff'}">
                                    <i class="cs-icon icon-new-document file-name-text" [ngStyle]="{'color': validationFailed ? 'red' : '@green-dark'}"></i>
                                    {{selectedFileName}}
                                 </span>
                                 <div class="pull-right" style="margin-left: 20px;">
                                    <button type="button" class="btn cs-btn-secondary-inverse" (click)="clearSelectedFile()" style="font-weight: bold;">
                                          <i class="glyphicon glyphicon-remove remove-file-text" ></i>Remove</button>
                                 </div>
                              </div>
                              <p-progressBar [value]="progressValue" [showValue]="false" *ngIf="!validationFailed"></p-progressBar>
                           </a>

                        </div>
                     </div>
                     <div class="row"  style="padding-bottom: 10px">
                           <span class="control-label" style="margin:0px 0px 10px 20px; font-weight: bold; font-size: 13px;" *ngIf="rowsImported > 0">
                              File delimiter: {{getDelimiterName()}}
                           </span>
                     </div>
                  </div>
               </div>
            </div>

            <div class="row" *ngIf="formValid">
               <div class="cs-wizard-step-panel col-sm-12">
                  <div class="panel-body">
                     <div style="padding-left:0px; padding-right:0px">
                        <div class="row">
                           <div class="col-12-sm" style="text-align: left; margin-bottom: 10px;">
                              <h6 style="margin-bottom: 20px;">Data preview</h6>
                              <span class="control-label" *ngIf="rowsImported > 0">{{rowsImported}} rows and {{columnsImported}} columns successfully validated</span>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-12-sm">
                              <p-dataTable class="black-datatable" [resizableColumns]="true" scrollable="true" scrollHeight="250px"
                                           [value]="callersPreview" [reorderableColumns]="false"
                                 #callersdt>
                                 <p-column field="Key" header="Territory" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Name" header="Name" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Address1" header="Address1" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Address2" header="Address2" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Address3" header="Address3" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Address4" header="Address4" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Address5" header="Address5" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="PostalCode" header="Postal Code" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Latitude" header="Latitude" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Longitude" header="Longitude" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="DatesClosed" header="Dates Closed" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="StartDay" header="Start Day" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="StartLunch" header="Start Lunch" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="EndLunch" header="End Lunch" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="EndDay" header="End Day" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Day1" header="Day 1" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Day2" header="Day 2" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Day3" header="Day 3" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Day4" header="Day 4" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Day5" header="Day 5" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Day6" header="Day 6" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Day7" header="Day 7" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Team" header="Team" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Role" header="Role" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Other1" header="Other 1" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Other2" header="Other 2" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Other3" header="Other 3" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Other4" header="Other 4" [style]="{'width':'100px'}"></p-column>
                                 <p-column field="Other5" header="Other 5" [style]="{'width':'100px'}"></p-column>
                              </p-dataTable>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </perfect-scrollbar>
      </div>
   </div>
</form>

<ndc-dynamic *ngIf="showErrorDialog" [ndcDynamicComponent]="errorDialog" [ndcDynamicInputs]="dialogInput" [ndcDynamicOutputs]="dialogOutput">
</ndc-dynamic>
