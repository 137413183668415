<div class="container">

  <div class="login-position">
     <div class="login-callsmart-logo">
        <img src="./assets/icons/CS_Logo_large_OnBlack.png" alt="CallSmart">
     </div>

     <div class="login-container">
      <div class="password-reset-container">
        <form #form="ngForm" (ngSubmit)="onResetPassword(form)" class="form-horizontal" autocomplete="off">
           <p>Double check that the email address you are using is the one your organisation used to create your profile. Ask
              your company CallSmart administrator if you have any doubts around your email address.
           </p>
           <p>If you need to reset your password you can do so by following the instructions below.</p>
           <div class="cs-setting-panel">
              <div class="panel-body">
                 <h6>Reset password</h6>
                 <p>Please provide the email address you use to log in to CallSmart. We will send you an email 
                  with a verification code.
                 </p>
  
                 <div class="form-group row">
                    <label class="control-label">Email address</label>
                 </div>
  
                 <div class="form-group row">
                  <input type="email" id="email" name="email" placeholder="Email" 
                  required pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"                                                                                             
                     #loginEmail="ngModel" [(ngModel)]="email" class="form-control cs-form-control">
                  <div [hidden]="loginEmail.valid || loginEmail.untouched">
                     <div *ngIf="loginEmail.errors && loginEmail.errors.required">
                        Email is required
                     </div>
                     <div *ngIf="loginEmail.errors && loginEmail.errors.pattern">
                        Email is invalid
                     </div>
                  </div>

                  <br>

                  <div class="alert alert-danger fade in" *ngIf="showError">
                     <strong>Error! </strong>{{errorMessage}}
                  </div>
               </div>

              </div>
           </div>
           <br />
           <div class="form-group row">
            <div class="form-group-chkbox" style="float:left">
               <a style="cursor: pointer;" [routerLink]="['/login']">
                  <div class="pull-right">Go back to login?</div>
               </a>
            </div>

            <button class="btn cs-btn-primary-inverse" style="float:right; width:150px" type="submit" [disabled]="!form.valid">Continue</button>
         </div>
        </form>
     </div>

        <div style="position: absolute;font-size: 16px;left: 4px;margin-top: 34px;">
           <a style="cursor: pointer;" target="_blank" [routerLink]="['/legal']">
              Legal
           </a>
        </div>

        <div class="login-caci-logo">
           <img class="caci-logo" src="./assets/icons/CACI_Logo_White.png" alt="CACI">
        </div>
     </div>

  </div>
</div>
