import { timer as observableTimer, BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { User } from 'app/models/user';
import { AuthService } from 'app/services/auth.service';
import { environment } from 'environments/environment';
import { ErrorHandlerService } from 'app/services/error-handler.service';
import { Company } from 'app/models/company';
import { HeartBeatData } from 'app/models/heartBeatData';
import { AuthenticationDetails } from 'amazon-cognito-identity-js';

// General purpose of a store
// create a client side in-memory database for the application data
// put that client-side in-memory database inside a centralized service that we will call a Store
// ensure that the centralized service owns the data, by either ensuring its encapsulation or exposing it as immutable
// this centralized service will have reactive properties, we can subscribe to it to get notified when the Model data changes

// Authentication Behavior
// Snowdon uses Cookie Authentication.
// When a user logins in with there credentials an encrypted token in the form of a cookie is issued. This cookie is valid for 8 hours.
// This cookie's lifecycle is independnant of the users sesssion. This means if you create another session at a later date and the cookie has not expired it is valid for the new session.
// Developers and users did not want to continually re-login to the system during the course of the day if there credentials where still valid.
// On navigating to the root of the application 'http://localhost:4200/#/', The system will take the user to the login screen.
// When the login screen loads it validates if cookie is still valid if it still valid it navigates the user straight to the dashboard screen.
// There is no need to capture any new credentials.
// If the cookie has expired, or the user has explicitly logged out (this expires the cookie) The login screen is shown and the user must re-enter there credentials.
// Sys Admin Page
// The sys admin mage is an edge case, its end points do not have authentication enabled.
// The reason for this, is that the database tables may be dropped and re-crated there is no way to authenticate the user.

@Injectable()
export class AuthenticationStore {

   private _user_heartbeat_subscription: Subscription;

   // user Logged in
   private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
   public user$: Observable<User> = this._user.asObservable();

   private _userCompany: BehaviorSubject<Company> = new BehaviorSubject<Company>(null);
   public userCompany$: Observable<Company> = this._userCompany.asObservable();

   // used by the application store to orchestrate interactions between stores
   public userLoggedOut: Subject<any> = new Subject<any>();

   // used to validate when user has forgot password and reset it in the process
   public userForgotPassword: Subject<any> = new Subject<any>();

   // this may need to be an observale at a later date
   public usersActiveProjectId: number = 0;

   // returned data to enter the details for forgot password 
   public CodeDeliveryDetails: any;

   public get loggedInUser() {
      return this._user.getValue();
   }
   
   public get cognitoUser() {
      return this._authService.loggedInCognitoUser;
   }

   public get userCompany() {
      return this._userCompany.getValue();
   }

   //Login error
   private _loginError: BehaviorSubject<string> = new BehaviorSubject<string>(null);
   public loginError$: Observable<string> = this._loginError.asObservable();

   public passwordChanged: Subject<any> = new Subject<any>();

     //change password error
   private _changePasswordError: BehaviorSubject<string> = new BehaviorSubject<string>(null);
   public changePasswordError$: Observable<string> = this._changePasswordError.asObservable();

   //new password error
   private _newPasswordError: BehaviorSubject<string> = new BehaviorSubject<string>(null);
   public newPasswordError$: Observable<string> = this._newPasswordError.asObservable();

   private authenticationDetails: AuthenticationDetails;

   constructor(private _authService: AuthService,
      private _router: Router,
      private _errorHandler: ErrorHandlerService) {

   }

   private login(email: string, password: string) {
      this._authService.login(email, password)
         .subscribe(
            (user: User) => {
               this._user.next(user);
               //clear any previouse error messages
               this._loginError.next(null);

               this.loadUserCompany();
               this.navigateToLandingPage()

            },
            (error) => {
               this._user.next(null);
               if (error.status == 401) {
                  this._loginError.next(error.statusText + ' - ' + error.error.message);
               }
               else if(error.status == 403){
                  this._loginError.next(null);
                  this._router.navigate(['expired-password']);
               }
               else if (error.status == 0) {
                  this._loginError.next(error.statusText + ' - Attempt to connect to a remote server failed.');
               }
               else {
                  this._errorHandler.handleError(error);
               }
            }
         );
   }


   // will follow a two step authentication process first with cognito and then with backend API
   public cognitoLogin(email: string, password: string, newpassword: string = '') {

      // storing this as we need it for MFA
      this.authenticationDetails = new AuthenticationDetails({ Username: email, Password: password });

      this._authService.cognitoLogin(email, password)
         .subscribe(
            (cognitoUser: any) => {
               this.cognitoSuccess(cognitoUser, email, password, newpassword);
            },
            (error) => {
               this.cognitoError(error);
            }
         );
   }

   public setPasswordAfterFirstTimeLogin(email: string, password: string){
      this._authService.completeNewPassword(password)
      .subscribe(
         (cognitoUser: any) => {
            this.authenticationDetails = new AuthenticationDetails({ Username: email, Password: password });
            this.cognitoSuccess(cognitoUser, email, password);
         },
         (error) => {
            this.cognitoError(error);
         }
      );
   }

   public validateMFACode(confirmationCode: string) {
      this._authService.validateMFACode(confirmationCode)
         .subscribe(
            (cognitoUser: any) => {
               this.cognitoSuccess(cognitoUser, this.authenticationDetails.getUsername(), this.authenticationDetails.getPassword());
            },
            (error) => {
               this.cognitoError(error);
            }
         );
   }

   public expiredLogin(email: string,oldPassword: string, newpassword: string) {
      var accessToken = this._authService.loggedInCognitoUser.getSignInUserSession().getAccessToken().getJwtToken();
      
      this._authService.expiredLogin(email, oldPassword, newpassword, accessToken)
         .subscribe(
            (user: User) => {
               this._user.next(user);
               this._loginError.next(null);

               this.loadUserCompany();
               this.navigateToLandingPage()

            },
            (error) => {
               this._user.next(null);
               if (error.status == 401 || error.status == 403) {
                  this._loginError.next(error.statusText + ' - ' + error.error.message);
               }
               else if (error.status == 0) {
                  this._loginError.next(error.statusText + ' - Attempt to connect to a remote server failed.');
               }
               else {
                  this._errorHandler.handleError(error);
               }
            }
         );
   }

   // Cognito user pool does not have(as of now) the process to store password history or duration
   // first check with API for password history or duration
   public changePassword(oldPassword: string, newpassword: string) {
      var accessToken = this._authService.loggedInCognitoUser.getSignInUserSession().getAccessToken().getJwtToken();
      var payload = this.cognitoUser.signInUserSession.idToken.payload;
      var username = payload["email"];

      this._authService.changePassword(username, oldPassword, newpassword, accessToken)
         .subscribe(
            (data: any) => {
               this.passwordChanged.next(null);
               this._user.next(null);
               this.userLoggedOut.next(null);
               this._changePasswordError.next(null);
               this.cognitoLogout();
            },
            (error) => {
               if (error.status == 401 || error.status == 403) {
                  this._changePasswordError.next(error.error.message);
               }
               else if (error.status == 0) {
                  this._changePasswordError.next(error.statusText + ' - Attempt to connect to a remote server failed.');
               }
               else {
                  this._errorHandler.handleError(error);
               }
            }
         );
   }

   public updatePasswordHistory(newpassword: string){
      var email = this.cognitoUser.challengeParam;
      this._authService.updatePasswordHistory(email, newpassword)
      .subscribe(
         (isValid: boolean) => {
            this.setPasswordAfterFirstTimeLogin(email, newpassword)
         },
         (error) => {
            if (error.status == 401 || error.status == 403) {
               this._newPasswordError.next(error.error.message);
            }
            else {
               this._errorHandler.handleError(error);
            }
         }
      );
   }
   
   // going to API to validate the cognito user
   public doesCognitoUserExists(email: string) {
      this._authService.doesCognitoUserExists(email).subscribe((user: User) => { 
        this._loginError.next(null);
        this.cognitoResetPassword(email);
      },
      (error) => { this._loginError.next(error.error.message);
      });
   }

   // this is a password reset process, when we call this on cognito, it will send a verification
   // code to the given email
   public cognitoResetPassword(email: string) {
      this._authService.cognitoResetPassword(email).subscribe((data: any) => { 
         if(data.CodeDeliveryDetails != null){
            this.CodeDeliveryDetails = data.CodeDeliveryDetails
            this.CodeDeliveryDetails['username'] = email;

            this._router.navigate(['forgot-password']);
         }
      },
      (error) => { this._loginError.next(error); });
   }

   public resetPassword(email: string, password: string, verificationCode: string) {
      this._authService.resetPassword(email, password, verificationCode)
         .subscribe(
            (user: User) => {   
               this._loginError.next(null);
               this._router.navigate(['login']);
            },
            (error) => { this._loginError.next(error.error.message);}
         );
   }

   public logout() {
      // stop the user heartbeat
      this.stopUserHearBeat();

      this._authService.logout()
         .subscribe(
            (data: any) => {
               this._user.next(null);

               // TODO do we want to do routing in the store?
               this._router.navigate(['']);
               console.log(data);
               this.userLoggedOut.next(null);
            },
            (error) => this._errorHandler.handleError(error)
         );
   }

   public cognitoLogout() {
      this._authService.cognitoLogout()
      .subscribe(
         (data: any) => {
            localStorage.clear();
            this.logout();
         },
         (error) => this._errorHandler.handleError(error)
      );
   }

   public forcedLogout() {
      // stop the user heartbeat
      this.stopUserHearBeat();
      this._user.next(null);
      this.userLoggedOut.next(null);
      this._router.navigate(['']);
      window.location.reload(true);

   }

   public getUserFromCookie() {
      this._authService.getUserFromCookie()
         .subscribe(
            (isSessionValid: boolean) => {
               if (isSessionValid) {
                  this.isAuthenticated();
               }
               else {
                  this._user.next(null);
                  this._router.navigate(['']);
               }
            },
            (error: any) => {
               // will throw refresh session has expired, we do not want to call API
               this._authService.cognitoUser.signOut();
               this._authService.cognitoUser = null;
               this._user.next(null);
            });
   }

   public isAuthenticated() {
      this._authService.isAuthenticated()
         .subscribe(
            (user: User) => {
               if (user.userId != 0) {
                  this._user.next(user);

                  this.loadUserCompany();
                  return true;
               }
            },
            (error: any) => {
               this._user.next(null);
               this._errorHandler.handleError(error);
               return false;
            });
   }

   public stopUserHearBeat() {
      if (this._user_heartbeat_subscription) {
         this._user_heartbeat_subscription.unsubscribe();
      }
   }
   // setup the users heart beat
   public pollUserHeartBeat(userId: number) {
      this.stopUserHearBeat();
      //heart beat is set to 3min
      this._user_heartbeat_subscription = observableTimer(0, 180000).subscribe(() => this.setUserHearBeat(userId));

   }

   public getAuthorizationToken(){
      var cognitoUser = this._authService.loggedInCognitoUser;

      if(cognitoUser && cognitoUser.signInUserSession && cognitoUser.signInUserSession.idToken){
         return cognitoUser.signInUserSession.idToken.jwtToken;
      }

      return '';
   }
   
   // clear the user id and the heartbeat on the project, freeing it for use by another user
   public clearUserProjectHeartBeat(userId: number, projectId: number) {
      let data = new HeartBeatData();
      this.usersActiveProjectId = 0
      data.projectId = projectId;
      data.clearProjectHeartBeat = true;

      this._authService.clearUserProjectHeartBeat(userId, data).subscribe(
         (data: boolean) => {
         },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }

   private setUserHearBeat(userId: number) {
      let data = new HeartBeatData();
      data.projectId = this.usersActiveProjectId;

      this._authService.setUserHeartBeat(userId, data).subscribe(
         (data: boolean) => { },
         (error) => {
            this._errorHandler.handleError(error);
         }
      );
   }


   // the landing page should be the open projects page.
   // for developers we are constantly refreshing our pages with builds, we want it to be the
   // dashboard and auto open the first project
   private navigateToLandingPage() {
      if (environment.loadFirstProject) {
         this._router.navigate(['dashboard']);
      } else {
         this._router.navigate(['open-project']);
      }
   }

   // load the users company iformation
   public loadUserCompany() {
      this._authService.getUserCompany(this.loggedInUser.userId)
         .subscribe(
            (company: Company) => {
               this._userCompany.next(company);
            },
            (error) => {
               this._errorHandler.handleError(error);
            }
         );
   }

   // this function is now used by login and expired login process to authenticate with cognito 
   public cognitoSuccess(cognitoUser: any, email?: string, password?: string, newpassword: string = '') {

      switch (cognitoUser.challengeName) {
         case 'NEW_PASSWORD_REQUIRED':
            this._authService.cognitoUser = cognitoUser;
            this._router.navigate(['new-password']);
            break;
         case 'SMS_MFA':
            this._authService.cognitoUser = cognitoUser;
            this._router.navigate(['sms-mfa-verification']);
            break;
         default:
            localStorage.removeItem("userName");
            localStorage.setItem("userName", email);

            if (newpassword != undefined && typeof newpassword === 'string' && newpassword.length === 0) {
               this.login(email, password);
            } else{
               this.expiredLogin(email, password, newpassword);
            }

            // clear the sensitive data
           delete(this.authenticationDetails);
            break;
      }
   }

   public cognitoError(error: any) {

      this._user.next(null);

      switch (error.code) {
         case 'NotAuthorizedException':
         case 'CodeMismatchException':
            this._loginError.next(error.code + ' - ' + error.message);
            break;
         case 'EACCES':
            this._loginError.next(error.code + ' - Attempt to connect to a remote server failed.');
            break;
         default:
            this._loginError.next(error.code + ' - ' + error.message);
            break;
      }
   }
}
