<p-dialog [header]="headerText" [(visible)]="display" modal="true" width="700" positionTop="50" [draggable]="false" [resizable]="false"
   (onHide)="onCancel()">

   <form #form="ngForm" class="form-horizontal" autocomplete="off">
      <div>
         <p>The new user will be asked to complete their account via email.</p>
         <div class="cs-setting-panel">
            <div class="panel-body">
               <h6>Company</h6>
               <div class="form-group col-sm-5">
                  <callsmart-dropdown name="companies" [options]="companies" [(ngModel)]="selectedCompanyId" [style]="{'width':'175px'}"
                     [disabled]="loggedInUser.isCompanyAdmin" (onChange)="onCompaniesChanged()">
                  </callsmart-dropdown>
               </div>

            </div>
         </div>
         <div class="cs-setting-panel">
            <div class="panel-body">
               <h6>User details</h6>
               <div class="form-group">
                  <label for="name" class="col-sm-2 control-label" style="text-align: left;">Name</label>
                  <div class="col-sm-5">
                     <input type="name" id="name" #name=ngModel name="name" required [ngModel]="user.fullname" class="form-control cs-form-control">
                  </div>
               </div>
               <div class="form-group">
                  <label for="email" class="col-sm-2 control-label" style="text-align: left;">Email</label>
                  <div class="col-sm-5">
                     <input type="email" id="email" #email="ngModel" name="email" required [ngModel]="user.username" 
                                 [readonly]="this.readOnlyEmail"  class="form-control cs-form-control">
                  </div>

               </div>
               <div class="form-group" *ngIf="loggedInUser.isAdmin">
                  <label for="phoneNumber" class="col-sm-2 control-label" style="text-align: left;">Phone</label>
                  <div class="col-sm-5">
                     <input type="text" id="phoneNumber" #phoneNumber="ngModel" name="phoneNumber" placeholder="+44xxxxxxxxxx"
                        [ngModel]="user.phoneNumber" (input)="validatePhoneNumber(form.value.phoneNumber)"
                        class="form-control cs-form-control">
                  </div>

               </div>
               <div class="alert alert-danger fade in" *ngIf="showError">
                  {{errorMsg}}
               </div>
            </div>
         </div>
         <div class="cs-setting-panel" *ngIf="loggedInUser.isAdmin">
            <div class="panel-body">
               <h6>Admin</h6>

               <div [ngClass]="{'cs-block-ui': disableAdminCheckbox}">
                  <div class="form-group" style="padding-left: 15px">
                     <p-checkbox name="admin" [(ngModel)]="isAdmin" binary="true" (onChange)="onAdminChange(isAdmin)" label="CACI Administrator">
                     </p-checkbox>
                  </div>
               </div>

               <div [ngClass]="{'cs-block-ui': disableCompanyAdminCheckbox}">
                  <div class="form-group" style="padding-left: 15px">
                     <p-checkbox name="companyAdmin" [(ngModel)]="isCompanyAdmin" binary="true" label="Company administrator">
                     </p-checkbox>
                  </div>
               </div>
            </div>
         </div>
         <div class="cs-setting-panel" *ngIf="loggedInUser.isAdmin">
            <div class="panel-body">
               <h6>MFA</h6>

               <div [ngClass]="{'cs-block-ui': disableMFACheckbox}">
                  <div class="form-group" style="padding-left: 15px">
                     <p-checkbox name="mfaEnabled" [(ngModel)]="isMFAEnabled" binary="true" label="MFA Enabled">
                     </p-checkbox>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <p-footer>
         <div style="padding-top: 10px; margin-bottom:30px;">
            <button type="button" class="btn cs-btn-primary-inverse pull-right" [disabled]="!form.valid" (click)="onSave(form)">Save</button>
            <button type="button" class="btn cs-btn-secondary-inverse pull-right" (click)="onCancel()" style="margin-right: 8px;">Cancel</button>
         </div>
      </p-footer>

   </form>

</p-dialog>