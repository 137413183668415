<div class="container">

  <div class="login-position">
      <div class="login-callsmart-logo">
         <img src="./assets/icons/CS_Logo_large_OnBlack.png" alt="CallSmart">
      </div>

     <div class="login-container">
        <div class="form-box">
          <p>Enter your verification code.</p>
          <br />
           <form (ngSubmit)="onValidateCode(f)" #f="ngForm" autocomplete="off">

              <div class="form-group row">
                <span>
                  <label>Verification code has been sent to: {{challengeParam?.CODE_DELIVERY_DESTINATION}}</label>
               </span>
              </div>

              <div class="form-group row">
                 <input type="text" id="verificationCode" name="verificationCode" placeholder="Verification code" 
                     required maxlength="10" [(ngModel)]="verificationCode" #validateCode="ngModel"
                     class="form-control cs-form-control">
                     <span *ngIf="validateCode.touched && validateCode.errors">
                        <label *ngIf="validateCode.errors.required">Verification code is required</label>
                     </span>
              </div>

              <div class="form-group row"></div>
      
            <div class="row">
               <div class="alert alert-danger fade in" *ngIf="showError">
                  {{errorMessage}}
               </div>
            </div>

              <div class="form-group row">
                  <div class="form-group-chkbox" style="float:left">
                     <a style="cursor: pointer;" [routerLink]="['/login']">
                        <div class="pull-right">Go back to login?</div>
                     </a>
                  </div>
                 <button class="btn cs-btn-primary-inverse" style="float:right; width:220px"
                 [disabled]="!f.valid" type="submit">Submit</button>

              </div>
           </form>
        </div>

        <div style="position: absolute;font-size: 16px;left: 4px;margin-top: 34px;">
         <a style="cursor: pointer;" target="_blank" [routerLink]="['/legal']">
            Legal
         </a>
      </div>

      <div class="login-caci-logo">
         <img class="caci-logo" src="./assets/icons/CACI_Logo_White.png" alt="CACI">
      </div>
     </div>

  </div>
</div>

