<div class="container">

  <div class="login-position">
    <div class="login-callsmart-logo">
      <img src="./assets/icons/CS_Logo_large_OnBlack.png" alt="CallSmart">
    </div>
    <div class="login-container">
      <div class="form-box">
        <p>Please enter your verification code and a new password.</p>
        <form (ngSubmit)="onConfirmNewPassword(f)" #f="ngForm" autocomplete="off">

          <div class="form-group row">
            <span>
              <label>Verification code has been sent to: {{CodeDeliveryDetails?.Destination}}</label>
            </span>
          </div>

          <div class="form-group row">
            <input type="text" id="verificationCode" name="verificationCode" placeholder="Verification code" required
              maxlength="10" [(ngModel)]="verificationCode" #validateCode="ngModel"
              class="form-control cs-form-control">
            <span *ngIf="validateCode.touched && validateCode.errors">
              <label *ngIf="validateCode.errors.required">Verification code is required</label>
            </span>
          </div>

          <div class="form-group row">
            <input type="password" id="newPassword" name="newPassword" placeholder="New password" required
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?!@#%&/\\,><':;|_~`=+\- ])(.{12,})"
              [(ngModel)]="newPassword" #loginNewPassword="ngModel" class="form-control cs-form-control">
            <span *ngIf="loginNewPassword.touched && loginNewPassword.errors">
              <label *ngIf="loginNewPassword.errors.required">Please insert new password</label>
              <label *ngIf="loginNewPassword.errors.pattern">Invalid password</label>
            </span>
          </div>

          <div class="form-group row">
            <input type="password" id="confirmPassword" name="confirmPassword" placeholder="Confirm new Password"
              required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?!@#%&/\\,><':;|_~`=+\- ])(.{12,})"
              [(ngModel)]="confirmPassword" #loginConfirmPassword="ngModel" class="form-control cs-form-control">
            <span *ngIf="loginConfirmPassword.touched && loginConfirmPassword.errors">
              <label *ngIf="loginConfirmPassword.errors.required">Please insert confirmation password</label>
              <label *ngIf="loginConfirmPassword.errors.pattern">Invalid password</label>
            </span>
          </div>

          <div class="form-group row"></div>

          <div class="row">
            <div class="alert alert-danger fade in" *ngIf="showError">
              <strong>Error! </strong>{{errorMessage}}
            </div>
          </div>
          <div class="form-group row">
            <div class="form-group-chkbox" style="float:left">
              <a style="cursor: pointer;" [routerLink]="['/login']">
                 <div class="pull-right">Go back to login?</div>
              </a>
           </div>
            <button class="btn cs-btn-primary-inverse" style="float:right; width:220px" type="submit">Confirm
              changes</button>

          </div>
        </form>
      </div>

      <div style="position: absolute;font-size: 16px;left: 4px;margin-top: 34px;">
        <a style="cursor: pointer;" target="_blank" [routerLink]="['/legal']">
          Legal
        </a>
      </div>

      <div class="login-caci-logo">
        <img class="caci-logo" src="./assets/icons/CACI_Logo_White.png" alt="CACI">
      </div>
    </div>

  </div>
</div>