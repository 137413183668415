import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectItem } from 'primeng/primeng';

import { ApplicationStore } from 'app/stores/application-store';
import { BrowserWindowService } from 'app/services/browser-window.service';
import { User } from 'app/models/user';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { DialogMode } from 'app/models/diary/event-dialog-modes';
import { Company } from 'app/models/company';

/**
 * Add / edit user dialog
 */
@Component({
   selector: 'callsmart-add-edit-user-dialog',
   templateUrl: './add-edit-user-dialog.component.html'
})
export class AddEditUserDialogComponent implements OnInit {

   // User to add or edit.
   @Input() user: User;

   // Header text for the dialog.
   @Input() headerText: string;

   // Determines the mode this dialog is in.
   @Input() dialogMode: DialogMode;

   // Controls the visibility of this dialog, clients can set this to true to display it.
   @Input() display: boolean = false;

   // Notifies the client when the dialog was closed with save button.
   @Output() save = new EventEmitter<any>();

   // Notifies the client when the dialog was closed with cancel button.
   @Output() cancel = new EventEmitter<void>();

   // Determine whether to display the email error message.
   public showError: boolean = false;

   // Determine whether to disable the admin checkbox.
   public disableAdminCheckbox = false;

   // Determine whether to disable the company admin checkbox.
   public disableCompanyAdminCheckbox = false;

   // The selected company by the user.
   public selectedCompanyId: number;

   // The list of companies to display in the dropdown.
   public companies: SelectItem[] = [];

   // Email valdiation error message to display
   public errorMsg: string;

   public readOnlyEmail: boolean = false;

   public disableMFACheckbox = false;

   // Determine the admin status of the user.
   private _isAdmin: boolean = false;
   public get isAdmin() {
      return this.dialogMode === DialogMode.add ? this._isAdmin : this.user.isAdmin;
   }
   public set isAdmin(newValue: boolean) {
      if (this.dialogMode === DialogMode.add) {
         this._isAdmin = newValue;
      }
      else {
         this.user.isAdmin = newValue;
      }
   }

   // Determine the company admin status of the user.
   private _isCompanyAdmin: boolean = false;
   public get isCompanyAdmin() {
      return this.dialogMode === DialogMode.add ? this._isCompanyAdmin : this.user.isCompanyAdmin;
   }
   public set isCompanyAdmin(newValue: boolean) {
      if (this.dialogMode === DialogMode.add) {
         this._isCompanyAdmin = newValue;
      }
      else {
         this.user.isCompanyAdmin = newValue;
      }
   }
   
   // Determine the mfa status of the user.
   private _isMFAEnabled: boolean = false;
   public get isMFAEnabled() {
      return this.dialogMode === DialogMode.add ? this._isMFAEnabled : this.user.isMFAEnabled;
   }
   public set isMFAEnabled(newValue: boolean) {
      if (this.dialogMode === DialogMode.add) {
         this._isMFAEnabled = newValue;
      }
      else {
         this.user.isMFAEnabled = newValue;
      }
   }

   public get loggedInUser(): User {
      return this._applicationStore.authenticationStore.loggedInUser;
   }

   constructor(private _applicationStore: ApplicationStore) {
   }

   public ngOnInit() {
      this.buildCompaniesList(this._applicationStore.sysAdminStore.currentCompanies);
      this.initialiseDialog();
   }

   public initialiseDialog() {
      // Initialise the company drop down list.
      if (this.dialogMode === DialogMode.edit) {
         this.selectedCompanyId = this.user.companyId;
         this.readOnlyEmail = true;
      }
      else {
         this.selectedCompanyId = this.companies[0].value;
      }

      // Initialise the admin check boxes.
      this.onCompaniesChanged();
      this.disableMFACheckbox =  !CallsmartUtils.validatePhoneNumber(this.user.phoneNumber);
   }

   public onSave(form: NgForm) {
      this.showError = false;

      let isValidEmail = CallsmartUtils.validateEmail(form.value.email);
      let isValidPhone = (!form.value.mfaEnabled) ? true : CallsmartUtils.validatePhoneNumber(form.value.phoneNumber);

      if (isValidEmail && isValidPhone) {

         if (this.dialogMode === DialogMode.add) {
            if (this.isEmailUnique(form.value.email)) {
               this.addUser(form);
               this.display = false;
               this.save.next();
            } else {
               this.errorMsg = 'A User with the same email already exists, please choose a different email.';
               this.showError = true;
            }
         }
         else {
            this.updateUser(form);
            this.display = false;
            this.save.next();
         }

      }
      else {
         this.errorMsg =  isValidEmail ?
         'Invalid phone number. Please provide a valid phone number.'
         : 'Invalid email address format. Please provide a valid email address format.';
         this.showError = true;
      }
   }

   public onCompaniesChanged() {
      if (this.selectedCompanyId === 1) {
         this.disableCompanyAdminCheckbox = true;
         this.disableAdminCheckbox = false;
         this.isCompanyAdmin = false;
      }
      else {
         this.disableCompanyAdminCheckbox = false;
         this.disableAdminCheckbox = true;
         this.isAdmin = false;
         this.isMFAEnabled = false;
      }
   }

   private isEmailUnique(email: string): boolean {
      let users = this._applicationStore.sysAdminStore.users;
      let index = users.findIndex(user => user.username === email);
      return index == -1;
   }

   private updateUser(form: NgForm) {
      this.user.fullname = form.value.name;
      this.user.username = form.value.email;
      this.user.phoneNumber = form.value.phoneNumber;
      this.user.isAdmin = form.value.admin;
      this.user.isCompanyAdmin = form.value.companyAdmin;
      this.user.companyId = this.selectedCompanyId;
      this.user.isMFAEnabled = form.value.mfaEnabled

      this._applicationStore.sysAdminStore.updateUser(this.user);
   }

   private addUser(form: NgForm) {
      let user = new User();
      user.fullname = form.value.name;
      user.username = form.value.email;
      user.phoneNumber = form.value.phoneNumber;
      user.isAdmin = this.isAdmin;
      user.isCompanyAdmin = this.isCompanyAdmin;
      user.companyId = this.selectedCompanyId;
      user.isMFAEnabled = this.isMFAEnabled;

      this._applicationStore.sysAdminStore.createUser(user);
   }

   // Called when the user clicks on the Cancel button or close icon in the
   // header.
   public onCancel() {
      this.display = false;
      this.cancel.next();
   }

   // Build dropdown combo box from list of companies.
   private buildCompaniesList(companyList: ReadonlyArray<Company>) {
      companyList.forEach(c => {
         this.companies.push({ label: c.companyName, value: c.companyId });
      })
   }

   public validatePhoneNumber(phoneNumber) {
      var isNumberValid = CallsmartUtils.validatePhoneNumber(phoneNumber);
      this.disableMFACheckbox = !isNumberValid;
   }
   
   public onAdminChange(isAdmin: boolean){
      this.disableMFACheckbox = !isAdmin;
      this.isMFAEnabled = isAdmin;
   }
}
