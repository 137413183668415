import { throwError as observableThrowError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConvert } from 'json2typescript';

import { environment } from 'environments/environment';
import { Company } from 'app/models/company';
import { User } from 'app/models/user';
import { TravelModel } from 'app/models/travelModel';
import { Project } from 'app/models/project';
import { CopyRollProject } from 'app/models/copy-roll-project';
import { Maintenance } from 'app/models/maintenance';

// this service is used purely for data acess CRUD operations to the data base
// the serbice is normally called from with in a store

// This service class manages the data access for administration tasks create new user create new company
@Injectable()
export class SysAdminService {

   accountUrl = `${environment.baseUrl}api/account`;

   constructor(private _http: HttpClient) { }

   public getUsers() {
      let url: string = `${this.accountUrl}/users`;
      return this._http.get<User[]>(url)
         .pipe(retry(3)).pipe(
            map(
               (response: any) => {
                  let userObjects: User[];
                  let jsonConvert: JsonConvert = new JsonConvert();

                  //Deserialise the users in to Typescript objects
                  try {
                     userObjects = jsonConvert.deserialize(response, User);
                  }
                  catch (e) {
                     //this._alertStore.sendAlert(new Alert('Error', (<Error>e).message));
                     return observableThrowError((<Error>e).message);
                  }
                  return userObjects;
               }),
            catchError(error => observableThrowError(error)));
   }

   public deleteUser(userId: number) {
      let url: string = `${this.accountUrl}/users/${userId}`;
      return this._http.delete(url)
         .pipe(retry(3)).pipe(
            map(() => {
               return true;
            }),
            catchError(error => observableThrowError(error)));
   }

   public createUser(user: User) {
      let url: string = `${this.accountUrl}/users`;

      return this._http.post(url, user)
         .pipe(map((response: any) => {
               if (response === null) {
                  //this._alertStore.sendAlert(new Alert('Error', "Returned company was null."));
                  return observableThrowError("Returned user was null.");
               }
               return response;
            }),
            catchError(error => observableThrowError(error)));
   }

   public updateUser(user: User) {
      let url: string = `${this.accountUrl}/users`;

      return this._http.put(url, user)
         .pipe(retry(3)).pipe(
            // .map((response: Response) => {
            //    const data = response.json();
            //    return data;
            // })
            catchError(error => observableThrowError(error)));
   }

   public syncAWSCognitoUserPool() {
      let url: string = `${this.accountUrl}/synccognitouserpool`;

      return this._http.post(url, null)
         .pipe(retry(1)).pipe(
            catchError(error => observableThrowError(error)));
   }

   public getTravelModels() {
      let url: string = `${this.accountUrl}/travelmodels`;
      return this._http.get<TravelModel[]>(url)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               let travelModelObjects: TravelModel[];
               let jsonConvert: JsonConvert = new JsonConvert();

               //Deserialise the users in to Typescript objects
               try {
                  travelModelObjects = jsonConvert.deserialize(response, TravelModel);
               }
               catch (e) {
                  return observableThrowError((<Error>e).message);
               }
               return travelModelObjects;
            }),
            catchError(error => observableThrowError(error)));
   }
   public deleteTravelModel(travelModelId: number) {
      let url: string = `${this.accountUrl}/travelmodels/${travelModelId}`;
      return this._http.delete(url)
         .pipe(retry(3)).pipe(
            map(() => {
               return true;
            }),
            catchError(error => observableThrowError(error)));
   }

   public createTravelModel(travelModel: TravelModel) {
      let url: string = `${this.accountUrl}/travelmodels`;

      return this._http.post(url, travelModel)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               if (response === null) {
                  //this._alertStore.sendAlert(new Alert('Error', "Returned company was null."));
                  return observableThrowError("Returned travel model was null.");
               }
               return response;
            }),
            catchError(error => observableThrowError(error)));
   }

   public updateTravelModel(travelModel: TravelModel) {
      let url: string = `${this.accountUrl}/travelmodels`;

      return this._http.put(url, travelModel)
         .pipe(retry(3)).pipe(
            // .map((response: Response) => {
            //    const data = response.json();
            //    return data;
            // })
            catchError(error => observableThrowError(error)));
   }


   public getCompanies() {
      let url: string = `${this.accountUrl}/companies`;
      return this._http.get<Company[]>(url)
         .pipe(retry(3)).pipe(
            map(
               (response: any) => {
                  let companyObjects: Company[];
                  let jsonConvert: JsonConvert = new JsonConvert();

                  //Deserialise the users in to Typescript objects
                  try {
                     companyObjects = jsonConvert.deserialize(response, Company);
                  }
                  catch (e) {
                     //this._alertStore.sendAlert(new Alert('Error', (<Error>e).message));
                     return observableThrowError((<Error>e).message);
                  }
                  return companyObjects;
               }),
            catchError(error => observableThrowError(error)));
   }

   public deleteCompany(companyId: number) {
      let url: string = `${this.accountUrl}/companies/${companyId}`;
      return this._http.delete(url)
         .pipe(retry(3)).pipe(
            map(() => {
               return true;
            }),
            catchError(error => observableThrowError(error)));
   }

   public createCompany(company: Company) {
      let url: string = `${this.accountUrl}/companies`;

      return this._http.post(url, company)
         .pipe(retry(3)).pipe(
            map((response: any) => {
               if (response === null) {
                  //this._alertStore.sendAlert(new Alert('Error', "Returned company was null."));
                  return observableThrowError("Returned company was null.");
               }
               return response;
            }),
            catchError(error => observableThrowError(error)));
   }

   public updateCompany(company: Company) {
      let url: string = `${this.accountUrl}/companies`;

      return this._http.put(url, company)
         .pipe(retry(3)).pipe(
            // .map((response: Response) => {
            //    const data = response.json();
            //    return data;
            // })
            catchError(error => observableThrowError(error)));
   }

   public getProjects(companyId: number) {
      let url: string = `${environment.baseUrl}/api/adminprojects/${companyId}`;
      return this._http.get<Project>(url)
         .pipe(retry(3)).pipe(
            map(
               (response: any) => {
                  let projectObjects: Project[];
                  let jsonConvert: JsonConvert = new JsonConvert();

                  //Deserialise the callers in to Typescript objects
                  try {
                     projectObjects = jsonConvert.deserialize(response, Project);
                  }
                  catch (e) {
                     return observableThrowError((<Error>e).message);
                  }

                  return projectObjects;
               }),
            catchError(error => observableThrowError(error)));
   }

   public cloneProject(projectId: number, newProjectName: string, userId: number) {
      let dto = new CopyRollProject();
      let url: string = `${environment.baseUrl}/api/adminprojects/${projectId}`;

      dto.newName = newProjectName;
      dto.orginalProjectId = projectId;
      dto.userId = userId;

      return this._http.post(url, dto)
         .pipe(retry(3)).pipe(
            map(() => {
               return true;
            }),
            catchError(error => observableThrowError(error)));
   }

   public getMaintenance() {
      let url: string = `${environment.baseUrl}/api/maintenance`;
      return this._http.get<Maintenance>(url)
         .pipe(
            map(
               (response: Maintenance) => {
                  let maintenance: Maintenance;
                  let jsonConvert: JsonConvert = new JsonConvert();

                  try {
                     maintenance = jsonConvert.deserialize(response, Maintenance);
                  }
                  catch (e) {
                     return observableThrowError((<Error>e).message);
                  }

                  return maintenance;
               }),
            catchError(error => observableThrowError(error)));
   }

   public updateMaintenance(maintenance: Maintenance) {
      let url: string = `${environment.baseUrl}/api/maintenance`;

      return this._http.put(url, maintenance)
         .pipe(retry(3)).pipe(
            catchError(error => observableThrowError(error)));
   }
}
