import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { Project } from 'app/models/project';
import { User } from 'app/models/user';
import { ApplicationStore } from 'app/stores/application-store';
import { ChangePasswordComponent } from 'app/change-password/change-password.component';
import { ProjectStatus } from 'app/models/projectStatus';
import { Company } from 'app/models/company';
import { HelpDialogComponent } from 'app/shared/help-dialog/help-dialog.component';
import { RollProjectForwardDialogComponent } from 'app/open-project-workspace/roll-project-forward-dialog/roll-project-forward-dialog.component';
import { Alert } from 'app/models/alert';


@Component({
   selector: 'callsmart-navbar',
   templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit, OnDestroy {

   public showMenu: boolean = false;
   public showOpenProject: boolean = true;
   public user: User;
   public userCompany: Company
   public project: Project;
   public hasProjectLoaded: boolean = false;

   // Determines whether to display the change password dialog.
   public showChangePasswordDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public changePasswordDialog = ChangePasswordComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public changePasswordDialogInput = { display: false };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public changePasswordDialogOutput = {
      saved: () => this.onChangePasswordSaveEvent(),
      cancel: () => this.onChangePasswordCancelEvent()
   };

   // Determines whether to display the help dialog.
   public showHelpDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public helpDialog = HelpDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public helpDialogInput = { display: false };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public helpDialogOutput = {
      cancel: () => this.onHelpCancelEvent()
   };

   // Determines whether to display the roll forward dialog.
   public showRollProjectFowardDialog: boolean = false;

   // Tells the dynamic component loader (ndc-dynamic) the type of the component to be loaded.
   public rollProjectForwardDialog = RollProjectForwardDialogComponent;

   // Input parameters for the loaded component. This usually will be the
   // @Input() properties.
   public rollProjectForwardDialogInput = {
      display: false,
      project: null
   };

   // Output parameters for the loaded component. This usually be any
   // @Output() properties like EventEmitters.
   public rollProjectForwardDialogOutput = {
      roll: () => this.onRollFowardEvent(),
      cancel: () => this.onCancelRollFowardEvent()
   };

   private _projectInitialised: boolean = false;
   private _projectSelected: boolean = false;
   private _projectsCount: number;
   
   private _subscriptions: Subscription = new Subscription();
   private _user_company_subscription: Subscription;
   private _projects_subscription: Subscription;

   private urlsToIgnore:string[] = ['/', '', '/expired-password', '/new-password', '/sms-mfa-verification', '/legal',
             '/sms-mfa-verification', '/password-reset', '/forgot-password'];

   constructor(private _router: Router, private _applicationStore: ApplicationStore) { }

   public ngOnInit(): void {
      this._router.events.subscribe((val) => {
         if (val instanceof NavigationEnd) {
            if(this.menuShouldNotBeShown(val)) {
               this.showMenu = false;
            } else {
               this.showMenu = true;
               this.user = this._applicationStore.authenticationStore.loggedInUser;
            }

            // determine if open project should be shown
            if(val.url === '/open-project'){
               this.showOpenProject = false;
            }else{
               this.showOpenProject = true;
            }
         }
      });

      this.subscribeToInitialiseProjectStatus();
      this.subscribeToSelectedProject();
      this.subscribeToProjects();
      this.subscribeToUserCompany();
   }

   private menuShouldNotBeShown(val: any) {
      return this.urlsToIgnore.includes(val.url) || this.urlsToIgnore.includes(val.urlAfterRedirects) 
                        || val.url.startsWith('/error');
   }


   //public logout(): void {
   //   this._applicationStore.authenticationStore.logout();
   //}


   public ngOnDestroy(): void {
      this._subscriptions.unsubscribe();

      if( this._user_company_subscription){
         this._user_company_subscription.unsubscribe();
      }

      if (this._projects_subscription) {
         this._projects_subscription.unsubscribe();
      }
   }

   public changePassword(): void {
      this.showChangePasswordDialog = true;
      this.changePasswordDialogInput.display = true;
   }

   // cancel button from the add\edit event dialog
   public onChangePasswordCancelEvent(): void {
      this.showChangePasswordDialog = false;
      this.changePasswordDialogInput.display = false;
   }

   // save button from the change password dialog
   public onChangePasswordSaveEvent(): void {
      this.showChangePasswordDialog = false;
      this.changePasswordDialogInput.display = false;
   }

   public showHelp(): void {
      this.showHelpDialog = true;
      this.helpDialogInput.display = true;
   }

   // cancel button from the add\edit event dialog
   public onHelpCancelEvent(): void {
      this.showHelpDialog = false;
      this.helpDialogInput.display = false;
   }

   public rollForward() {
      this.showRollProjectFowardDialog = true;
      this.rollProjectForwardDialogInput.display = true;
      this.rollProjectForwardDialogInput.project = this.project;
      this._applicationStore.projectsStore.loadProjectWithStatusCheck = false;
   }

   // delete button from the roll project  dialog
   public onRollFowardEvent() {
      this.showRollProjectFowardDialog = false;
      this.rollProjectForwardDialogInput.display = false;
      this._applicationStore.projectsStore.refreshProjectData();
      this._router.navigate(['dashboard']);
   }

   // cancel button from the roll project  dialog
   public onCancelRollFowardEvent() {
      this.showRollProjectFowardDialog = false;
      this.rollProjectForwardDialogInput.display = false;
   }

   public onCreateNewProject() {
      if (this.userCompany.trialLicense) {
         if (this._projectsCount == 1) {
            // For a trial license, only one project can be created.
            this._applicationStore.alertStore.sendAlert(new Alert("Warning", "Your licence does not allow for multiple projects."));
         }
         else {
            this._router.navigate(['new-project']);
         }
      }
      else {
         this._router.navigate(['new-project']);
      }
   }

   private subscribeToSelectedProject(): void {
      this._subscriptions.add(this._applicationStore.projectsStore.selectedProject$.subscribe(
         (selectedproject: any) => {
            this.project = this._applicationStore.projectsStore.selectedProject;
            this._projectSelected = this.project != null;
            this.hasProjectLoaded = this._projectSelected && this._projectInitialised;
         }
      ));
   }

   private subscribeToInitialiseProjectStatus(): void {
      this._applicationStore.projectsStore.projectStatus$.subscribe((status: ProjectStatus) => {
         this._projectInitialised = status.drivetimeCount == status.callerCount
            && status.isProjectInitialising == false; //status.scheduleCount == status.callerCount;
         this.hasProjectLoaded = this._projectSelected && this._projectInitialised;
      });
   }

   private subscribeToUserCompany():void{
      this._user_company_subscription = this._applicationStore.authenticationStore.userCompany$.subscribe(
         (company: Company) => {
            this.userCompany = company;
         }
      );
   }

   private subscribeToProjects() {
      this._projects_subscription = this._applicationStore.projectsStore.projects$.subscribe(
         (projects: ReadonlyArray<Project>) => {
            this._projectsCount = projects.length;
         }
      );
   }
}
