import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApplicationStore } from 'app/stores/application-store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'callsmart-password-reset',
  templateUrl: './password-reset.component.html'
})
export class PasswordResetComponent implements OnInit, OnDestroy {

  private _loginError_subscription: Subscription;

  // Determines the visibility of the error message.
  public showError: boolean = false;

  // The error message to display.
  public errorMessage: string;
  public email: string;

  constructor(private _applicationStore: ApplicationStore ) { }

  ngOnInit() {
    this.showError = false;
    this.errorMessage = '';
    this.subscribeToLoginError();
  }

  ngOnDestroy(): void {
   if (this._loginError_subscription) {
      this._loginError_subscription.unsubscribe();
   }
  }

  private subscribeToLoginError() {
    this._loginError_subscription = this._applicationStore.authenticationStore.loginError$.subscribe(
       (error: string) => {
          if (error) {
             this.showError = true;
             this.errorMessage = error;
          }
       }
    );
  }

  public onResetPassword(form: NgForm) {
    if (form.valid) {
      this.showError = false;
      this._applicationStore.authenticationStore.doesCognitoUserExists(this.email);
    }
 }

}
