import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApplicationStore } from 'app/stores/application-store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'callsmart-sms-mfa-verification',
  templateUrl: './sms-mfa-verification.component.html'
})
export class SmsMfaVerificationComponent implements OnInit, OnDestroy {

  public challengeParam: any;
  public verificationCode: string;
  public errorMessage: string = '';
  public showError: boolean = false;

  private _loginError_subscription: Subscription;

  constructor(private _applicationStore: ApplicationStore ) {
    this.challengeParam = this._applicationStore.authenticationStore.cognitoUser.challengeParam;
   }

  ngOnInit() { 
    this.subscribeToLoginError();
    this.showError = false;
    this.errorMessage = '';
  }

  ngOnDestroy(): void {
    if (this._loginError_subscription) {
      this._loginError_subscription.unsubscribe();
   }
  }
 
  public onValidateCode(form: NgForm) {
    if (form.valid) {
      this._applicationStore.authenticationStore.validateMFACode(this.verificationCode);
    }
  }

  private subscribeToLoginError() {
    this._loginError_subscription = this._applicationStore.authenticationStore.loginError$.subscribe(
       (error: string) => {
          if (error) {
             this.showError = true;
             this.errorMessage = error;
          }
       }
    );
 }
}
