import { Component, Input, ViewChild, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PapaParseService } from 'ngx-papaparse';
import { DataTable } from 'primeng/components/datatable/datatable';
import { FileUpload } from 'primeng/components/fileupload/fileupload';
import { ConfirmationService } from 'primeng/primeng';
import { Subscription } from 'rxjs';

import { NewProjectWizard } from 'app/models/newProjectWizard';
import { DataTableColumnHelper } from 'app/shared/dataTableColumnHelper';
import { ErrorDialogComponent } from 'app/shared/error-dialog/error-dialog.component';
import { ApplicationStore } from 'app/stores/application-store';
import { environment } from 'environments/environment';
import { CallsmartUtils } from 'app/shared/callsmart-utils';
import { ErrorData } from 'app/models/error-data';
import { ErrorStore } from 'app/stores/error-store';


export const allColumnHeaders = ["Key", "Name", "Address1", "Address2", "Address3", "Address4", "Address5", "PostalCode", "Latitude", "Longitude",
   "DatesClosed", "StartDay", "StartLunch", "EndLunch", "EndDay", "Day1", "Day2", "Day3", "Day4", "Day5", "Day6", "Day7", "Team", "Role", "Other1",
   "Other2", "Other3", "Other4", "Other5"];

export const geocodeHeaders = ["Key", "Name", "PostalCode"];
export const nonGeocodeHeaders = ["Key", "Name", "Latitude", "Longitude"];

@Component({
   selector: 'callsmart-new-project-callers',
   templateUrl: './new-project-callers.component.html',
   providers: [PapaParseService]
})
export class NewProjectCallersComponent implements OnInit, OnDestroy {

   public uploadUrl: string;
   public selectedFileName: string = '';

   public callersPreview: any[];  // Stores the first 5 rows parsed to be displayed.
   public rowsImported: number = 0; // Number of rows imported.
   public columnsImported: number = 0; // Number of non empty columns imported.
   public validationFailed: boolean = false; // Flag to determine if the validation has failed.
   public progressValue: number = 0; // Value used to display the proggress bar.
   public csvDelimiter: string = '';
   public dataTemplate = environment.clientBaseUrl + 'data-templates/DataTemplates.xlsx';
   
   private _validationErrors: string[] = []; // Collection of errors found during the validation proccess.
   private _interval: any = null; // Timer used by the progress bar
   private _errorDialog_subscription: Subscription;
   private readonly _errorSource: string = 'callers';

   // Error Dialog component properties
   private _projectWizardModel: NewProjectWizard; // Model object which cached the info from the wizard.
   @Input()
   get projectWizardModel(): NewProjectWizard {
      return this._projectWizardModel;
   }
   set projectWizardModel(newProjectWizardModel: NewProjectWizard) {
      this._projectWizardModel = newProjectWizardModel;
   }

   @Input() componentHeight: number;
   @Input() importDataMode: boolean = false;

   @Output() uploadComplete: EventEmitter<void> = new EventEmitter<void>();

   @ViewChild('callersForm') form: NgForm;
   @ViewChild('fileUpload') fileUpload: FileUpload;
   @ViewChild('callersdt') callersDt: DataTable;

   public errorDialog = ErrorDialogComponent; // Dialog to display validation errors.
   public showErrorDialog: boolean = false; // Sets the visibility of the error dialog component.

   // Properties for the error dialog dynamic loading
   public dialogInput = {
      display: false,
      validationErrors: this._validationErrors,
      title: '',
      bodyText: '',
      bottomLink: ''
   };
   public dialogOutput = {
      cancel: () => this.onCancelEvent()
   };

   public get errorSource() {
      return this._errorSource;
   }

   constructor(
      private _errorStore: ErrorStore,
      private _papa: PapaParseService,
      private _applicationStore: ApplicationStore,
      private _confirmationService: ConfirmationService) {
   }

   ngOnInit() {
      this._errorDialog_subscription = this._errorStore.errorDialogShown.subscribe(
         (errorSource: string) => {
            if(errorSource == this._errorSource) {
               this.resetSelectedFile();
            }
         }
      );
   }

   ngOnDestroy() {
      if (this._errorDialog_subscription) {
         this._errorDialog_subscription.unsubscribe();
      }
   }

   public uploadFileToServer() {
      if (this.fileUpload.files.length == 1 && this.projectWizardModel.hasStartedCallersUploadedToServer == false) {
         this.fileUpload.upload();
         this.projectWizardModel.hasStartedCallersUploadedToServer = true;
      }
   }

   onBeforeSend(event) {
      event.xhr.setRequestHeader("Authorization", `Bearer ${this._applicationStore.authenticationStore.getAuthorizationToken()}`);
   }

   public onUpload() {
      this.projectWizardModel.hasCompletedCallersUploadedToServer = true;
      this.uploadComplete.next();
   }

   public onUploadError(event: any): void {
      let request: XMLHttpRequest = event.xhr;
      let response: any = JSON.parse(request.response);

      this.projectWizardModel.callerUploadedToServerError = true;

      let errors: string[] = [];
      errors = response.message.split('\r\n\r\n')

      if (errors.length == 0) {
         errors.push(response.message);
      }else{
         if(errors[errors.length-1]==''){
            errors.pop();
         }
      }

      this._errorStore.sendError(new ErrorData('Unable to import',
         'The file you are trying to import could not be processed due to one or more errors.\n Please check the files to ensure you are using the correct template and that the file contains valid data:',
         errors, '/data-templates/DataTemplates.xlsx',
         this._errorSource));

   }

   public clearSelectedFile() {

      this._confirmationService.confirm({
         message: 'Are you sure you want to select a different file?<br>Doing so will delete any callers, callpoints or events you have configured.',
         header: 'Confirmation',
         icon: 'fa fa-question-circle',
         accept: () => {
            this.fileUpload.clear();
            this.resetSelectedFile();
         },
         reject: () => {
            //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
         }
      });
   }

   // Csv file is selected and then validated according to the callsmart business rules.
   public onSelect(event) {

      // resets the file delimiter
      this.csvDelimiter = '';
      // URL need to be built as only here does the project id
      this.uploadUrl = `${environment.baseUrl}/api/projects/${this._projectWizardModel.projectId}/callers/file`;
      // Initializing properties before validation.
      this.resetSelectedFile();
      // Progress bar is triggered.
      this.startProgressBar();

      let file = event.files[0];
      this.selectedFileName = file.name;

      // Flag to determine is the headers have already been checked.
      let isHeaderChecked: boolean = false;
      let index: number = 0; // current row
      this._validationErrors = []; // Array which contains the errors found when validating the Csv file
      let temporaryCallersPreview: any[] = [];
      let emptyColumnValues: number[][] = [];
      let callerKeys: string[] = [];

      // Parsing the file row by row
      this._papa.parse(file, {
         //delimiter: '	',
         dynamicTyping: true,
         header: true,
         skipEmptyLines: true,
         encoding: 'ISO-8859-1',
         step: function (results, parser) {
            // Headers are checked when parsing the first row.
            // This checking is done only once.
            if (!isHeaderChecked) {
               isHeaderChecked = true;
               let areHeadersValid: boolean = this.isDelimiterAllowed(results.meta.delimiter) && this.isCsvHeaderValid(results.meta.fields);
               if (!areHeadersValid) {
                  // If the headers are not valid, parsing proccess is aborted.
                  parser.abort();
                  return;
               }
               this.csvDelimiter = results.meta.delimiter;
            }
            // Parses and validates a the current row.
            index = this.parseCsvRow(results, index, emptyColumnValues, temporaryCallersPreview, callerKeys);
         }.bind(this),
         complete: function (results, file) {
            if(this._validationErrors.length === 0 && index === 0){
               this._validationErrors.push('File does not contain data. No records found.');
            }

            // Check how many callers are being imported against the limit set by the license for this company.
            // Here we are only checking when a new project is being created. When importing to existing project
            // this validation is done in the import data summary component.
            if(this._applicationStore.authenticationStore.userCompany.trialLicense) {
               if (this._projectWizardModel.fileMode == 'createnew') {
                  if(index > this._applicationStore.authenticationStore.userCompany.trialLicenseCallerLimit)
                  {
                     this._validationErrors.push('Your licence does not allow you to import more than ' + 
                        this._applicationStore.authenticationStore.userCompany.trialLicenseCallerLimit + ' callers.');
                     this.validationFailed = true;
                  }
               } 
            }

            // Parsing and validation process has ended.
            this.showSummary();
            if (this._validationErrors.length == 0) {
               this.setParsedCsvResults(index, emptyColumnValues, temporaryCallersPreview);
            }
            else {
               this.validationFailed = true;
            }
            this.stopProgessBar();
         }.bind(this),
      })
   }

   // Cancel button from the add\edit event dialog
   public onCancelEvent() {
      this.showErrorDialog = false;
      this.dialogInput.display = false;
      this.resetSelectedFile();
   }

   // Removes the tooltip from the fileUpload control when mousing over
   public mouseOver() {
      this.fileUpload.advancedFileInput.nativeElement.title = '';
   }

   // Checks whether the form is valid.
   public get formValid(): boolean {
      //return this.form.valid && !this.validationFailed && this.selectedFileName!=='';
      return this.selectedFileName.length > 0 && !this.validationFailed;
   }

   // Gets the delimiter name
   public getDelimiterName(): string {
      switch (this.csvDelimiter) {
         case ';':
            return 'Semicolon';
         case ',':
            return 'Comma';
         case '\t':
            return 'Tab';
         default:
            return '';
      }
   }

   // Parses and validates a Csv row.
   private parseCsvRow(results: any, index: number, emptyColumnValues: number[][], temporaryCallersPreview: any[], callerKeys: string[]): number {

      // Checking mandatory fields.
      this.areRequiredFieldsValid(results.data[0], index + 1)

      // Check and change if necessary the lat long format.
      this.processLatLongFormat(results.data[0]);

      // Checking field data types.
      this.areDataTypesValid(results.data[0], index + 1);

      this.areLatLongValidCoordinates(results.data[0], index + 1);

      // Adding the format errors found in this line during the parsing proccess to the _validationErrors array.
      results.errors.forEach(error => {
         this._validationErrors.push(`${error.message} (Line ${index + 1}).`);
      });

      //check for duplicate keys
      if (callerKeys.find(f => f === results.data[0]['Key']) !== undefined) {
         this._validationErrors.push(`Duplicate key value ${results.data[0]['Key']} (Line ${index + 1}).`);
      } else {
         callerKeys.push(results.data[0]['Key']);
      }

      // Preview data
      if (index >= 0 && index <= 4) {
         if (index === 0) {
            this.projectWizardModel.delimiter = results.meta['delimiter'];
         }

         let columnIndex: number = 0;
         let emptyIndexes: number[] = [];
         // Gets the column indexes with empty values
         // let rowValues = (<any>Object).values(results.data[0]); // this code is not supported in IE
         let rowValues = Object.keys(results.data).map(itm => results.data[itm]);

         rowValues.forEach(element => {
            if (!element) {
               emptyIndexes.push(columnIndex);
            }
            columnIndex++;
         });
         emptyColumnValues[index] = emptyIndexes;
         temporaryCallersPreview.push(results.data[0]);
      }
      index++;
      return index;
   }

   // Deal with euro number formats, if the delimeter is ';' then the numbers will use comma instead of period
   // for the decimal point. Replace the comma's with period so that fields can be parsed correctly.
   private processLatLongFormat(row: any) {
      if (this.csvDelimiter === ';') {
         let fLatitude: string = row['Latitude'];
         let fLongitude: string = row['Longitude'];
         row['Latitude'] = parseFloat(fLatitude.replace(',', '.'));
         row['Longitude'] = parseFloat(fLongitude.replace(',', '.'));
      }
   }

   // Set the project wizard model properties related to callers
   // after parsing and validating the Csv file
   private setParsedCsvResults(index: number, emptyColumnValues: number[][],
      temporaryCallersPreview: any[]) {
      this.callersDt.columns.forEach(column => {
         column.hidden = false;
      });

      //checks the columns with empty values
      let emptyColumns: number[] = DataTableColumnHelper.getEmptyValueIndexes(emptyColumnValues);
      // Hidding the columns whose cells are all empty
      emptyColumns.forEach(emptyColumnIndex => {
         this.callersDt.columns[emptyColumnIndex].hidden = true;
      });
      this.columnsImported = this.callersDt.columns.length - emptyColumns.length;

      // callers preview info is displayed
      this.callersPreview = temporaryCallersPreview.slice();
      this.rowsImported = index;
      this.validationFailed = false;
      this.projectWizardModel.callersFileName = this.selectedFileName;
      this.projectWizardModel.numberOfCallers = index;
      // Resets the events for the new callers file.
      this._applicationStore.projectsStore.updateCreateNewProjectEventsSettings(this.projectWizardModel.projectId, []);
      this._projectWizardModel.projectEvents = [];
   }

   // Resets and initialises all variables to start the uploading process from the scratch.
   private resetSelectedFile() {
      // delete the data
      this._applicationStore.callpointsStore.deleteCallpointsByProjectId(this.projectWizardModel.projectId);
      this._applicationStore.callersStore.deleteCallersByProjectId(this.projectWizardModel.projectId);
      // TODO delete the Calendar files
      this.projectWizardModel.hasStartedCallersUploadedToServer = false;
      this.projectWizardModel.hasCompletedCallersUploadedToServer = false;
      this.projectWizardModel.callerUploadedToServerError = false;
      this.projectWizardModel.callersFileName = '';
      this.projectWizardModel.callpointsFileName = ''
      this.projectWizardModel.numberOfCallers = 0;
      this.projectWizardModel.numberOfCallpoints = 0;
      this.selectedFileName = '';
      this.callersPreview = [];
      this.rowsImported = 0;
      this.validationFailed = false;
   }

   private startProgressBar() {
      let maxValue = 100;
      this._interval = setInterval(() => {
         if (this.progressValue < maxValue) {
            this.progressValue += 10;
         }
         else {
            this.progressValue = 0;
         }
      }, 1000);
   }

   private stopProgessBar() {
      this.progressValue = 100;
      clearInterval(this._interval);
   }

   // Checks if the Csv file header is valid according to the Callsmart bussines rules.
   private isCsvHeaderValid(fields: string[]): boolean {

      // Checking the header field names.
      let missingHeaderNames: string[] = this.checkHeaderNames(fields);
      // Checking wether the header fields are in the rigth order.
      let isHeaderOrderValid: boolean = this.checkHeaderOrder(fields);

      if (missingHeaderNames.length > 0 && !isHeaderOrderValid) {
         // 8-Sep-22, some column headers are missing, now check whether the headers have the
         // minimum columns required to continue
         let isHeaderContentValid: boolean = this.checkGeocodeHeaderNames(fields);
         
         if(!isHeaderContentValid){
            isHeaderContentValid = this.checkNonGeocodeHeaderNames(fields);
         }

         if(isHeaderContentValid){
           return true;
         }
      }
      
      // Adding validation errors found during the header checking.
      missingHeaderNames.forEach(missingHeader => {
         this._validationErrors.push(`Missing column: ${missingHeader}`);
      });

      // This error is added only if header names are valid.
      if (!isHeaderOrderValid && missingHeaderNames.length > 0) {
         this._validationErrors.push('File keys are not defined in the right order.');
      }

      return missingHeaderNames.length == 0 && isHeaderOrderValid;
   }

   // Checks if the Csv file contains the required header fields.
   private checkHeaderNames(fields: string[]): string[] {
      return allColumnHeaders.filter(key => {
         if (!fields.includes(key))
            return key;
      });
   }

   private checkGeocodeHeaderNames(fields: string[]): boolean {
      let hasMandatoryColumns : boolean = true;
      for (let i: number = 0; i < geocodeHeaders.length; i++) {
         if (!fields.includes(geocodeHeaders[i])){
            hasMandatoryColumns = false;
            break;
         }
      }
      return hasMandatoryColumns;
   }

   private checkNonGeocodeHeaderNames(fields: string[]): boolean {
      let hasMandatoryColumns : boolean = true;
      for (let i: number = 0; i < nonGeocodeHeaders.length; i++) {
         if (!fields.includes(nonGeocodeHeaders[i])){
            hasMandatoryColumns = false;
            break;
         }
      }
      return hasMandatoryColumns;
   }

   // Checks if the Csv file contains the header fields in the right order.
   private checkHeaderOrder(fields: string[]): boolean {
      return fields[0] === 'Key' &&
         fields[1] === 'Name' &&
         fields[2] === 'Address1' &&
         fields[3] === 'Address2' &&
         fields[4] === 'Address3' &&
         fields[5] === 'Address4' &&
         fields[6] === 'Address5' &&
         fields[7] === 'PostalCode' &&
         fields[8] === 'Latitude' &&
         fields[9] === 'Longitude' &&
         fields[10] === 'DatesClosed' &&
         fields[11] === 'StartDay' &&
         fields[12] === 'StartLunch' &&
         fields[13] === 'EndLunch' &&
         fields[14] === 'EndDay' &&
         fields[15] === 'Day1' &&
         fields[16] === 'Day2' &&
         fields[17] === 'Day3' &&
         fields[18] === 'Day4' &&
         fields[19] === 'Day5' &&
         fields[20] === 'Day6' &&
         fields[21] === 'Day7' &&
         fields[22] === 'Team' &&
         fields[23] === 'Role' &&
         fields[24] === 'Other1' &&
         fields[25] === 'Other2' &&
         fields[26] === 'Other3' &&
         fields[27] === 'Other4' &&
         fields[28] === 'Other5';
   }

   // Checks if a Csv file row contains the mandatory fields
   private areRequiredFieldsValid(row: any, index: number): boolean {

      let invalidColumns = [];

      let hasPostcode = !CallsmartUtils.isNullOrEmpty(row['PostalCode']);
      let isLatLngZero = (row['Longitude'] == 0 && row['Latitude'] == 0);
      let isLatLngNull = (row['Longitude'] == null && row['Latitude'] == null);

      //let geoCode: boolean = (row['Longitude'] != 0 && row['Latitude'] != 0) || !CallsmartUtils.isNullOrEmpty(row['PostalCode'])
      let geoCode = (!isLatLngZero && !isLatLngNull) || hasPostcode;

      if (!geoCode) {
         if (row['Longitude'] == 0 && CallsmartUtils.isNullOrEmpty(row['PostalCode'])) {
            invalidColumns.push('Longitude');
         }

         if (row['Longitude'] == null && CallsmartUtils.isNullOrEmpty(row['PostalCode'])) {
            invalidColumns.push('Longitude');
         }

         if (row['Latitude'] == 0 && CallsmartUtils.isNullOrEmpty(row['PostalCode'])) {
            invalidColumns.push('Latitude');
         }

         if (row['Latitude'] == null && CallsmartUtils.isNullOrEmpty(row['PostalCode'])) {
            invalidColumns.push('Latitude');
         }

         if ((row['Longitude'] != 0 && row['Latitude'] != 0) && !CallsmartUtils.isNullOrEmpty(row['PostalCode'])) {
            invalidColumns.push('PostalCode');
         }

         if ((row['Longitude'] != null && row['Latitude'] != null) && !CallsmartUtils.isNullOrEmpty(row['PostalCode'])) {
            invalidColumns.push('PostalCode');
         }
      }

      if (CallsmartUtils.isNullOrEmpty(row['Key'])) {
         invalidColumns.push('Key');
      }

      if (CallsmartUtils.isNullOrEmpty(row['Name'])) {
         invalidColumns.push('Name');
      }

      
      if (invalidColumns.length > 0) {
         this._validationErrors.push(`Mandatory fields not found (Line ${index})  Column(s) ${invalidColumns.join(', ')}`);
      }
      return invalidColumns.length == 0;
   }

   // Checks whether the values for a Csv file row match the expected data types
   private areDataTypesValid(row: any, index: number): boolean {

      let invalidColumns = [];

      if (typeof row['Key'] !== 'string' && typeof row['Key'] !== 'number') {
         invalidColumns.push('Key');
      }

      if ((typeof row['Name'] !== 'string' && typeof row['Name'] !== 'number')) {
         invalidColumns.push('Name');
      }

      if ((typeof row['Address1'] !== 'string' && typeof row['Address1'] !== 'number')) {
         if (row['Address1'] != null) {
            invalidColumns.push('Address1');
         }
      }

      if ((typeof row['Address2'] !== 'string' && typeof row['Address2'] !== 'number')) {
         if (row['Address2'] != null) {
            invalidColumns.push('Address2');
         }
      }

      if ((typeof row['Address3'] !== 'string' && typeof row['Address3'] !== 'number')) {
         if (row['Address3'] != null) {
            invalidColumns.push('Address3');
         }
      }

      if ((typeof row['Address4'] !== 'string' && typeof row['Address4'] !== 'number')) {
         if (row['Address4'] != null) {
            invalidColumns.push('Address4');
         }
      }

      if ((typeof row['Address5'] !== 'string' && typeof row['Address5'] !== 'number')) {
         if (row['Address5'] != null) {
            invalidColumns.push('Address5');
         }
      }

      if ((typeof row['PostalCode'] !== 'string' && typeof row['PostalCode'] !== 'number')) {
         if (row['PostalCode'] != null) {
            invalidColumns.push('PostalCode');
         }
      }

      if (typeof row['Latitude'] !== 'number') {
         if (row['Latitude'] != null && row['Latitude'] != '') {
            invalidColumns.push('Latitude');
         }
      }

      if (typeof row['Longitude'] !== 'number') {
         if (row['Longitude'] != null && row['Latitude'] != '') {
            invalidColumns.push('Longitude');
         }
      }

      if (typeof row['StartDay'] !== 'number' && !CallsmartUtils.isNullOrEmpty(row['StartDay'])) {
         invalidColumns.push('StartDay');
      }

      if (typeof row['StartLunch'] !== 'number' && !CallsmartUtils.isNullOrEmpty(row['StartLunch'])) {
         invalidColumns.push('StartLunch');
      }

      if (typeof row['EndLunch'] !== 'number' && !CallsmartUtils.isNullOrEmpty(row['EndLunch'])) {
         invalidColumns.push('EndLunch');
      }

      if (typeof row['EndDay'] !== 'number' && !CallsmartUtils.isNullOrEmpty(row['EndDay'])) {
         invalidColumns.push('EndDay');
      }

      if ((typeof row['Day1'] !== 'string' && !CallsmartUtils.isNullOrEmpty(row['Day1']))) {
            invalidColumns.push('Day1');
      }

      if ((typeof row['Day2'] !== 'string' && !CallsmartUtils.isNullOrEmpty(row['Day2']))) {
         invalidColumns.push('Day2');
      }

      if ((typeof row['Day3'] !== 'string' && !CallsmartUtils.isNullOrEmpty(row['Day3']))) {
         invalidColumns.push('Day3');
      }

      if ((typeof row['Day4'] !== 'string' && !CallsmartUtils.isNullOrEmpty(row['Day4']))) {
         invalidColumns.push('Day4');
      }

      if ((typeof row['Day5'] !== 'string' && !CallsmartUtils.isNullOrEmpty(row['Day5']))) {
         invalidColumns.push('Day5');
      }

      if ((typeof row['Day6'] !== 'string' && !CallsmartUtils.isNullOrEmpty(row['Day6']))) {
         invalidColumns.push('Day6');
      }

      if ((typeof row['Day7'] !== 'string' && !CallsmartUtils.isNullOrEmpty(row['Day7']))) {
         invalidColumns.push('Day7');
      }

      if ((typeof row['Team'] !== 'string' && typeof row['Team'] !== 'number')) {
         if (row['Team'] != null) {
            invalidColumns.push('Team');
         }
      }

      if ((typeof row['Role'] !== 'string' && typeof row['Role'] !== 'number')) {
         if (row['Role'] != null) {
            invalidColumns.push('Role');
         }
      }

      if (typeof row['Other1'] !== 'string' && typeof row['Other1'] !== 'number' && !CallsmartUtils.isNullOrEmpty(row['Other1'])) {
         invalidColumns.push('Other1');
      }

      if (typeof row['Other2'] !== 'string' && typeof row['Other2'] !== 'number' && !CallsmartUtils.isNullOrEmpty(row['Other2'])) {
         invalidColumns.push('Other2');
      }

      if (typeof row['Other3'] !== 'string' && typeof row['Other3'] !== 'number' && !CallsmartUtils.isNullOrEmpty(row['Other3'])) {
         invalidColumns.push('Other3');
      }

      if (typeof row['Other4'] !== 'string' && typeof row['Other4'] !== 'number' && !CallsmartUtils.isNullOrEmpty(row['Other4'])) {
         invalidColumns.push('Other4');
      }

      if (typeof row['Other5'] !== 'string' && typeof row['Other5'] !== 'number' && !CallsmartUtils.isNullOrEmpty(row['Other5'])) {
         invalidColumns.push('Other5');
      }
      
      if (!this.isDaysClosedFormat(row['DatesClosed'])) {
         if (row['DatesClosed'] != null) {
            invalidColumns.push('DatesClosed');
         }
      }

      if (invalidColumns.length > 0) {
         this._validationErrors.push(`Unexpected data types. Line (${index}) column(s) ${invalidColumns.join(', ')}`);
      }
      return invalidColumns.length == 0;
   }

   // Only accept letters 'o', 'O', 'X', 'x' '1' and '2' for half days.
   private isDaysClosedFormat(dateClosed: string): boolean {
      let pattern = new RegExp(/^[OX12]+$/, 'i');
      return pattern.test(dateClosed);
   }

   // Displays the error dialog with the parsing errors found.
   private showSummary() {
      if (this._validationErrors.length > 0) {
         this.showErrorDialog = true;
         this.dialogInput.validationErrors = this._validationErrors;
         this.dialogInput.title = 'Unable to import';
         this.dialogInput.bodyText = 'The file you are trying to import could not be processed due to one or more errors.\nPlease check the files to ensure you are using the correct template and that the file contains valid data:'
         this.dialogInput.display = true;
         this.dialogInput.bottomLink = '/data-templates/DataTemplates.xlsx'
      }
   }

   // Checks the delimiter used by the csv file
   private isDelimiterAllowed(delimiter: string): boolean {
      let isValidDelimiter: boolean = delimiter == ',' || delimiter == ';' || delimiter == '\t';
      if (!isValidDelimiter) {
         this._validationErrors.push('Allowed file delimiters: comma, semicolon, tab.');
      }
      return isValidDelimiter;
   }

   // check Latitude and Latitude values are inside the coordinate system
   private areLatLongValidCoordinates(row: any, index: number): boolean {
      let invalidColumns = [];
      
      if (row['Latitude'] != null && row['Latitude'] != '') {
         let fLatitude: number = parseFloat(row['Latitude']);

         if (fLatitude < -90 || fLatitude > 90){
            invalidColumns.push('Latitude');
         }
      }

      if (row['Longitude'] != null && row['Longitude'] != '') {
         let fLongitude: number = parseFloat(row['Longitude']);

         if (fLongitude < -180 || fLongitude > 180){
            invalidColumns.push('Longitude');
         }
      }

      if (invalidColumns.length > 0) {
         this._validationErrors.push(`Line (${index}) column(s) ${invalidColumns.join(', ')} is outside the coordinate system and invalid`);
      }
   
      return invalidColumns.length == 0;
   }
}
